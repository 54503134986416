import Cross from '../../assets/crossIcon.png'
import Dropdown from '../Dropdown/Dropdown'
import ArrowDownDark from '../../assets/arrowDownDark.png'
import { SpinnerCircular } from 'spinners-react'

type Props = {
  onClose: () => void
  onFullName: (e: any) => void
  onPhoneNumberInput: (e: any) => void
  onStateDropdown: (e: any) => void
  onDistrictDropdownT: (e: any) => void
  onBlockDropdownT: (e: any) => void
  onCreateAdmin: () => void
  stateValue: string
  districtValue: string
  onDropdown: () => void
  onTypeDropdown: (title: string) => void
  isTypeDropdown: boolean
  typeDropdownValue: string
  value?: string
  selectedValue?: any
  selectedDistrict?: any
  stateDropdownValue?: string
  districtDropdownValue?: string
  blockDropdownValue?: string
  buttonText: string
  text: string
  isFromEdit?: boolean
  fullName?: string
  disable?: boolean
  isLoader?: boolean
  phoneNumber?: string
  phoneValue?: string
  editableData?: any
}

function AddAdminModal({
  onClose,
  onFullName,
  onPhoneNumberInput,
  onStateDropdown,
  onDistrictDropdownT,
  onBlockDropdownT,
  onCreateAdmin,
  stateValue,
  districtValue,
  onDropdown,
  onTypeDropdown,
  isTypeDropdown,
  typeDropdownValue,
  value,
  buttonText,
  text,
  stateDropdownValue,
  districtDropdownValue,
  blockDropdownValue,
  isFromEdit,
  fullName,
  disable,
  isLoader,
  phoneNumber,
  phoneValue,
}: Props) {
  console.log("block", blockDropdownValue)
  console.log("typed", typeDropdownValue)
  let data = require('../../datanew.json')
  let stateSelect = document.getElementById('stateSelect') as HTMLSelectElement
  let districtSelect = document.getElementById(
    'districtSelect',
  ) as HTMLSelectElement

  const onDistrictDropdown = () => {
    if (stateValue === 'Select State') {
      alert('Please select state first')
      return
    }
  }

  const onBlockDropdown = () => {
    if (stateValue === 'Select State' && districtValue === 'Select District') {
      alert('Please select state and district first')
      return
    } else if (districtValue === 'Select District') {
      alert('Please select district first')
      return
    }
  }

  let arr = [
    { name: 'State Level Access' },
    { name: 'District Level Access' },
    { name: 'Block Level Access' },
  ]

  let arr2 = [{ name: 'District Level Access' }, { name: 'Block Level Access' }]

  let arr3 = [{ name: 'District Level Access' }, { name: 'Block Level Access' }]

  const getValues = () => {
    if (localStorage.getItem('subRole') == 'super') return arr
    if (localStorage.getItem('subRole') == 'state') return arr2
    if (localStorage.getItem('subRole') == 'district') return arr3
  }

  return (
    <div className="modalOverlay" id="modalOverlay">
      <div className="modalInner pb-5">
        <div className="modalHeader mb-4 d-flex align-items-center justify-content-between px-3 py-2 w-100">
          <p>
            {' '}
            <span className="mx-1">+</span>
            {text} admin
          </p>
          <img src={Cross} alt="Cross Icon" onClick={onClose} />
        </div>
        <div className="modalContent px-4">
          <p className="mb-4">Enter Full Name</p>
          <input
            type="text"
            className="mb-4 px-3 w-100 py-1 addTitleInput"
            onChange={(e) => onFullName(e)}
            placeholder={fullName}
            value={value}
          />
          <p className="mb-4">Enter Phone No.</p>
          <div className="position-relative">
            <p className="code">+ 91</p>
            <input
              type="text"
              className="mb-4 w-100 py-1 phoneInputClass addTitleInput"
              maxLength={10}
              onChange={(e) => onPhoneNumberInput(e)}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault()
                }
              }}
              placeholder={phoneNumber}
              value={phoneValue}
            />
          </div>
          <div className="col mb-4">
            <Dropdown
              className="frameDropdown"
              dropdownListClass="dropdownListItems"
              onMouseMove={() => { }}
              onDropDown={onDropdown}
              onDropdownList={(title: string, ind: number) =>
                onTypeDropdown(title)
              }
              dropdownValue={typeDropdownValue}
              isDropdownList={isTypeDropdown}
              dropDownIndex={0}
              dropdownItems={getValues()}
              isSingleDropdown
            />
          </div>
          <div className="col position-relative mb-4">
            <img
              src={ArrowDownDark}
              alt="Arrow Down"
              className="dropdownArrow"
              style={{
                opacity: isFromEdit
                  ? stateDropdownValue === value &&
                    typeDropdownValue === 'Select Access Type'
                    ? 0.5
                    : 1
                  : typeDropdownValue === 'Select Access Type'
                    ? 0.5
                    : 1,
              }}
            />
            <select
              className="stateDropdown"
              id="stateSelect"
              onChange={(e) => onStateDropdown(e)}
              defaultValue={'state Level Access'}
              disabled={
                isFromEdit
                  ? stateDropdownValue === '' &&
                  typeDropdownValue === 'Select Access Type'
                  : typeDropdownValue === 'Select Access Type'
              }
            >
              <option selected>
                {stateDropdownValue ? stateDropdownValue : 'Select State'}
              </option>
              {data.map((item: any, index: number) => {
                return <option key={index}>{item.state}</option>
              })}
            </select>
          </div>
          <div className="col position-relative mb-4">
            <img
              src={ArrowDownDark}
              alt="Arrow Down"
              className="dropdownArrow"
              style={{
                opacity: isFromEdit
                  ? (districtDropdownValue === 'Select District' &&
                    typeDropdownValue === 'Select Access Type') ||
                    typeDropdownValue === 'state Level Access'
                    ? 0.5
                    : 1
                  : typeDropdownValue === 'Select Access Type' ||
                    typeDropdownValue === 'State Level Access'
                    ? 0.5
                    : 1,
              }}
            />
            <select
              className="stateDropdown"
              id="districtSelect"
              // value={value}
              onClick={onDistrictDropdown}
              onChange={(e) => onDistrictDropdownT(e)}
              disabled={
                isFromEdit
                  ? (districtDropdownValue === 'Select District' &&
                    typeDropdownValue === 'Select Access Type') ||
                  typeDropdownValue === 'state Level Access'
                  : typeDropdownValue === 'Select Access Type' ||
                  typeDropdownValue === 'State Level Access'
              }
            >
              <option selected disabled>
                {districtDropdownValue
                  ? districtDropdownValue
                  : 'Select District'}
              </option>
              {stateValue &&
                stateValue != '' &&
                data[
                  stateSelect?.selectedIndex ? stateSelect.selectedIndex - 1 : 0
                ].districts.map((item: any, index: number) => {
                  return <option key={index}>{item.district}</option>
                })}
            </select>
          </div>
          <div className="col position-relative mb-4">
            <img
              src={ArrowDownDark}
              alt="Arrow Down"
              className="dropdownArrow"
              style={{
                opacity: isFromEdit
                  ? typeDropdownValue === 'Select Access Type' ||
                    typeDropdownValue === 'state Level Access' ||
                    typeDropdownValue === 'district Level Access' && blockDropdownValue === 'Select block'
                    ? 0.5
                    : 1
                  : typeDropdownValue === 'Select Access Type' ||
                    typeDropdownValue === 'State Level Access' ||
                    typeDropdownValue === 'District Level Access'
                    ? 0.5
                    : 1,
              }}
            />
            <select
              className="stateDropdown"
              disabled={
                isFromEdit
                  ?
                  typeDropdownValue === 'Select Access Type' ||
                  typeDropdownValue === 'state Level Access' ||
                  typeDropdownValue === 'district Level Access' && blockDropdownValue === 'Select block'
                  : typeDropdownValue === 'Select Access Type' ||
                  typeDropdownValue === 'State Level Access' ||
                  typeDropdownValue === 'District Level Access'
              }
              onChange={(e) => onBlockDropdownT(e)}
              onClick={onBlockDropdown}
            >
              <option selected disabled>
                {blockDropdownValue ? blockDropdownValue : 'Select block'}
              </option>
              {stateValue &&
                districtValue &&
                stateValue != '' &&
                districtValue != '' &&
                data[
                  stateSelect?.selectedIndex ? stateSelect.selectedIndex - 1 : 0
                ].districts[
                  districtSelect?.selectedIndex
                    ? districtSelect.selectedIndex - 1
                    : 0
                ].subDistricts.villages.map((item: any, index: number) => {
                  return <option key={index}>{item}</option>
                })}
            </select>
          </div>
        </div>
        <button
          className="btn btn-lg addNewButton"
          onClick={onCreateAdmin}
          disabled={disable}
        >
          <span className="mx-1">+</span>
          {buttonText}
        </button>
      </div>
      {isLoader && (
        <div className="loaderContainer componentLoader">
          {' '}
          <SpinnerCircular enabled={true} />
        </div>
      )}
    </div>
  )
}

export default AddAdminModal
