import React from "react";
import { Route, Routes } from "react-router-dom";
import IecContent from "../pages/iecContent";
import RoleManagement from "../pages/roleManagement";
import LearningResources from "../pages/learningResources";
import Notification from "../pages/notification";
import PatientDirectory from "../pages/patientDirectory";
import Communication from "../pages/communication";
import Prom from "../pages/prom";
import Dashboard from "../pages/dashboard";

function RoutesComponent() {
  return (
    <Routes>
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/iecContent" element={<IecContent />} />
      <Route path="/roleManagement" element={<RoleManagement />} />
      <Route path="/learningResources" element={<LearningResources />} />
      <Route path="/notification" element={<Notification />} />
      <Route path="/patientDirectory" element={<PatientDirectory />} />
      <Route path="/communication" element={<Communication />} />
      <Route path="/prom" element={<Prom />} />
    </Routes>
  );
}

export default RoutesComponent;
