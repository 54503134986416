import React from 'react'
import Cross from "../assets/crossIcon.png";

type Props = {
    onClose: () => void;
    promOptions: any;
}

const PromsOptionModal = ({ onClose, promOptions }: Props) => {
    return (
        <div className="modalOverlay">
            <div className="modalInner pb-4">
                <div className="modalHeader mb-4 d-flex align-items-center justify-content-between px-3 py-2 w-100">
                    <p onClick={() => console.warn()}>Prom Answers</p>
                    <img src={Cross} alt="Cross Icon" onClick={onClose} />
                </div>
                <div className="modalContent px-4">
                    {promOptions?.map((option: any, index: number) => (
                        <>
                            <p>Option {index + 1}</p>
                            <div className=' pb-3 pt-2'>
                            <div>{`English:  `}{option.name.en}</div>
                            <div>{`Hindi:  `}{option.name.hi}</div>
                            <div>{`Marathi:  `}{option.name.mr}</div>
                            </div>
                        </>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default PromsOptionModal
