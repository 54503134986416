import React from "react";
import cross from "../assets/crossIcon.png"

type Props = {
  heading: string;
  onClose: () => void;
  title: string;
  subtitle: string
  src: any
};

function CommonModal({ heading, onClose, title, subtitle, src }: Props) {
  return (
    <div className="previewModalOverlay">
      <div className="modalContentMain pb-4">
        <div className="modalHeader mb-4 d-flex align-items-center justify-content-between px-3 py-2 w-100">
          <p>{heading}</p>
          <img src={cross} alt="Cross Icon" onClick={onClose} />
        </div>
        <div className="modalContent py-2 px-3">
        <h5 className="mb-3">{title}</h5>
        <img src={src} alt="" className="modalImage mb-3" />
        <p>{subtitle}</p>
        </div>
      </div>
    </div>
  );
}

export default CommonModal;
