import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: 'AIzaSyB9wE6jZ_PrCbrZsBQtTxkwRmyeVveqRlY',
    authDomain: 'jhpiego-dc74f.firebaseapp.com',
    projectId: 'jhpiego-dc74f',
    storageBucket: 'jhpiego-dc74f.appspot.com',
    messagingSenderId: '821375245870',
    appId: '1:821375245870:web:4b75d9c347a4a876e909bf',
    measurementId: 'G-7FFEL0974Q',
    databaseURL: "https://jhpiego-dc74f-default-rtdb.asia-southeast1.firebasedatabase.app"
};

if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} else {
    firebase.app();
}
export var storage = firebase.storage();
export var ref = firebase.storage();
export var getDownloadURL = firebase.storage();
export var uploadBytes = firebase.storage();
export var uploadString = firebase.storage();
export var getStorage = firebase.storage();
export const firestore = firebase.firestore();
export const auth = firebase.auth();

export default firebase;