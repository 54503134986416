import React from 'react'
import { useLocation } from 'react-router-dom';
import SelectRole from '../../pages/selectRole'
import RoutesComponent from '../RoutesComponent'
import SidebarComponent from '../Sidebar/Sidebar'

function Layout() {
    let location = useLocation();
  return (
    <>
    {location.pathname === '/' ? <SelectRole/>
    : 
    <div className="layout">
       <SidebarComponent />
      <div className="layoutContent">
      <RoutesComponent />
      </div>
    </div>
    }
    </>
  )
}

export default Layout