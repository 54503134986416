import React from "react";

type Props = {
  buttonText: string;
  isDarkBg?: boolean;
  isLightBg?: boolean;
  isDisabled?: boolean;
  icon?: any;
  className?: string;
  withBorder?: boolean;
  onButtonClick?: () => void;
  style?: any
  type?: "button" | "reset" | "submit" | undefined
};

function Button({ buttonText, isDarkBg, isLightBg, icon, className, withBorder, onButtonClick, isDisabled, style, type= 'button' }: Props) {
  return (
    <button
      className={`btn button btn-lg ${isDarkBg && "darkBg"} ${isLightBg && "lightBg"
        } ${className} ${withBorder && "withBorder"} ${isDisabled && "disable"}`}
      onClick={onButtonClick}
      style={style}
      type={type}
    >
      {icon && <span className="buttonIcon me-2">{icon}</span>}
      {buttonText}
    </button>
  );
}

export default Button;
