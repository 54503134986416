import { useEffect, useState } from "react";
import Button from "../components/Button/Button";
import ArrowDownIcon from "../assets/arrowDownDark.png";
import Rectangle from "../assets/rectangle.png";
import Dropdown from "../components/Dropdown/Dropdown";
import Cancel from "../assets/cancel.png";
import firebase from "../connection/firebase";
import { capitalize, gdm_list, topics } from "../libs/UtilityHelper";
import { SpinnerCircular } from "spinners-react";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarItems from "../components/SidebarItems";

function Notification() {
  const [isDeepLinkDropdown, setIsDeepLinkDropdown] = useState(false);
  const [deepLinkDropdownValue, setDeepLinkDropdownValue] = useState("None");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [subDescription, setSubDescription] = useState("");
  const [link, setLink] = useState("");
  const [notificationType, setNotificationType] = useState("Choose Type");
  const [topic, setTopic] = useState("Send To All");
  const [imageUrl, setImageUrl] = useState("");
  const [optionalImageUrl, setOptionalImageUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState<any>();
  const [optionalImage, setOptionalImage] = useState<any>();
  const [file, setFile] = useState<any>();
  const [optionalFile, setOptionalFile] = useState<any>();
  const [pool, setPool] = useState<string>("Send To All");
  const [GDM, setGDM] = useState<string>("Send To All");
  const [RCHInput, setRCHInput] = useState<string>("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("subRole") == undefined) {
      navigate("/")
    }
    else {
      if (localStorage.getItem("subRole") == "super") {
        let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
        let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == 'user') {
        let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
      }
      else {
        let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
      }
    }
  }, [])

  const onSubmit = () => {
    setLoader(true);
    const input = document.getElementById("inputImage");
    firebase
      .app()
      .functions("asia-south1")
      .httpsCallable(
        pool == "By RCH ID"
          ? "CMSsendNotificationToUser"
          : "CMSsendNotificationToTopic"
      )(
        JSON.stringify(
          pool != "By RCH ID"
            ? {
                title: title,
                description: description,
                type:
                  notificationType === "Choose Type"
                    ? "Null"
                    : notificationType,
                image: optionalImageUrl,
                topic:
                  pool != "GDM +ve"
                    ? Object.entries(topics).find((item) =>
                        item[0] == pool ? item[1] : ""
                      )![1]
                    : Object.entries(gdm_list).find(
                        (item) => item[0] == GDM
                      )![1],
                data:
                  notificationType == "Resource"
                    ? {
                        title: subTitle,
                        description: subDescription,
                        link: link,
                        image: imageUrl,
                      }
                    : {
                        title: subTitle,
                        description: subDescription,
                        link: link,
                      },
              }
            : {
                title: title,
                description: description,
                type:
                  notificationType === "Choose Type"
                    ? "Null"
                    : notificationType,
                image: optionalImageUrl,
                uid: RCHInput + "@jhpiego.com",
                data:
                  notificationType == "Resource"
                    ? {
                        title: subTitle,
                        description: subDescription,
                        link: link,
                        image: imageUrl,
                      }
                    : {
                        title: subTitle,
                        description: subDescription,
                        link: link,
                      },
              }
        )
      )
      .then(async (response) => {
        console.log("response", response);
        if (response.data == "Notification Posted") {
          window.location.reload();
          setPool("Send To All");
          alert("Upload successfully");
          setNotificationType("Choose Type");
        }
      });
    setTimeout(() => setLoader(false), 500);
  };

  const upload = async (imageDetails: any) => {
    if (imageDetails == null) return;
    const storage = firebase.storage();
    const storageRef = storage
      .ref(`/images/${imageDetails[0].name}`)
      .put(imageDetails[0]);
    storageRef.on(
      "state_changed",
      (snapShot) => {
       
        console.log(snapShot);
      },
      (err) => {
       
        console.log("Error: ", err);
      },
      () => {
        storage
          .ref("images")
          .child(imageDetails[0].name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log(fireBaseUrl);
           
            setImageUrl(fireBaseUrl);
          });
      }
    );
  };
  const uploadOptional = async (imageDetails: any) => {
    if (imageDetails == null) return;
    const storage = firebase.storage();
    const storageRef = storage
      .ref(`/images/${imageDetails[0].name}`)
      .put(imageDetails[0]);
    storageRef.on(
      "state_changed",
      (snapShot) => {
       
        console.log(snapShot);
      },
      (err) => {
        console.log("Error: ", err);
      },
      () => {
        storage
          .ref("images")
          .child(imageDetails[0].name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log(fireBaseUrl);
            setOptionalImageUrl(fireBaseUrl);
          });
      }
    );
  };

  const onImageUpload = (val: any) => {
    if (val.target.files[0].size > 5*1024*1024) {
      alert("Please upload file less than 2mb");
    } else{
      const img = new Image();
      img.src = URL.createObjectURL(val.target.files[0]);
      img.onload = () => {
        if(Number(img.height / img.width) > 0.5 && ((Number(img.height / img.width) < 0.8))){
          setFile(URL.createObjectURL(val.target.files[0]));
          setImage(val.target.files);
          upload(val.target.files);
        }
        else {
          alert("Please upload file with correct aspect ratio");
        }
      }
    }
    
  };
  const onOptionalImageUpload = (val: any) => {
    if (val.target.files[0].size > 5*1024*1024) {
      alert("Please upload file less than 2mb");
    }else{
      const img = new Image();
      img.src = URL.createObjectURL(val.target.files[0]);
      img.onload = () => {
        if(Number(img.height / img.width) > 0.5 && ((Number(img.height / img.width) < 0.8))){
    console.log("++", optionalFile);
    setOptionalFile(URL.createObjectURL(val.target.files[0]));
    setOptionalImage(val.target.files);
    uploadOptional(val.target.files);
        }
        else {
          alert("Please upload file with correct aspect ratio");
        }
      }
    }
  };

  return (
    <div className="container mt-4 mb-5">
      {!loader ? (
        <div className="row d-flex">
          <div className="col-md-8">
            <div className="card py-5 px-3 sendNotificationCard">
              <div className="row">
                <div className="row" style={{ marginBottom: "4rem" }}>
                  <div className="col-md-3 d-flex justify-content-start">
                    <p className="to">Notification</p>
                  </div>
                
                </div>
                <div className="row mb-5">
                  <div className="col-md-12 col-lg-6 d-flex align-items-start flex-column">
                    <p className="to" style={{ marginTop: "-30px" }}>
                      {" "}
                      Title (Max. 25 character)
                    </p>
                    <input
                      className="description"
                      placeholder="Enter Title"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                  </div>
                  <div
                    className="col-md-6"
                    style={{
                      border: "solid 2px #8D2338",
                      height: "100px",
                      display: "flex",
                      justifyContent: optionalFile ? "space-between" : "end",
                      alignItems: optionalFile ? "start" : "end",
                    }}
                  >
                    {optionalFile && (
                      <div className="position-relative">
                        <img className="mit" src={optionalFile} alt="" />
                        <img
                          className="nit"
                          src={Cancel}
                          alt=""
                          onClick={() => {
                            setOptionalFile(null);
                            setOptionalImage(null);
                          }}
                        />
                      </div>
                    )}
                    <div
                      className="button position-relative uploadFile"
                      style={{ marginTop: optionalFile ? "auto" : "0px" }}
                    >
                      <span style={{ cursor: "pointer" }}>Upload Image</span>
                      <input
                        type="file"
                        accept="image/jpeg, image/png, image/jpg"
                        id="inputImage"
                        className="notificationbtn"
                        onChange={(e) => onOptionalImageUpload(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 d-flex align-items-start flex-column">
                    <p className="to"> Description</p>
                    <input
                      className="title2"
                      onChange={(e) => setDescription(e.target.value)}
                      type="text"
                      placeholder="Enter Description"
                    />
                  </div>

                  <div className="col-md-6 deepLinkDropdownContainer">
                    <p className="to">User Pool</p>
                    <div className="position-relative">
                    <img
                      src={ArrowDownIcon}
                      alt="Icon"
                      className="selectIcon"
                    />
                    <select
                      className="pool"
                      onChange={(e) => setPool(e.target.value)}
                    >
                      <option selected>Send To All</option>
                      <option>By RCH ID</option>
                      <option>GDM +ve</option>
                      <option>GDM -ve</option>
                      <option>Not Tested</option>
                      <option>Guest Users</option>
                    </select>
                    </div>
                    {pool == "By RCH ID" && (
                      <input
                        className="rchId addTitleInput"
                        placeholder="ENTER RCH ID"
                        onChange={(e) => setRCHInput(e.target.value)}
                      />
                    )}
                  </div>

                  <div className="col-md-6 d-flex align-items-start flex-column">
                    <p className="to"> Type</p>
                    <div className="position-relative w-100">
                    <img
                      src={ArrowDownIcon}
                      alt="Icon"
                      className="selectIcon"
                    />
                    <select
                      className="topic"
                      onChange={(e) =>
                        setNotificationType(capitalize(e.target.value))
                      }
                    >
                      <option value="" disabled selected>
                        Choose Type
                      </option>
                      <option>Videos</option>
                      <option>Resource</option>
                      <option>Null</option>
                    </select>
                  </div>
                  </div>

                  {pool == "GDM +ve" && (
                    <div className="col-md-6 d-flex align-items-start flex-column">
                      <p className="to">GDM</p>
                      <div className="position-relative w-100">
                    <img
                      src={ArrowDownIcon}
                      alt="Icon"
                      className="selectIcon"
                    />
                      <select
                        className="topic"
                        onChange={(e) => {
                          setGDM(e.target.value);
                        }}
                      >
                        <option>Send To All</option>
                        <option>MNT</option>
                        <option>Exercise</option>
                        <option>Metformin</option>
                        <option>Insulin</option>
                      </select>
                    </div>
                    </div>
                  )}
                </div>

                {notificationType != "Choose Type" &&
                  notificationType != "Null" && (
                    <>
                      <h5 className="to videoText mt-5 mb-3">{`${notificationType} Notification`}</h5>
                      {notificationType == "Resource" && (
                        <p className="to videoText">Image</p>
                      )}
                      <div className="row mb-3">
                        {notificationType == "Resource" && (
                          <>
                            <div className="col-md-6 mb-4">
                              <div
                                className="px-3"
                                style={{
                                  border: "solid 2px #8D2338",
                                  height: "100px",
                                  display: "flex",
                                  justifyContent: file
                                    ? "space-between"
                                    : "end",
                                  alignItems: file ? "start" : "end",
                                }}
                              >
                                {file && (
                                  <div className="position-relative">
                                    <img className="mit" src={file} alt="" />
                                    <img
                                      className="nit"
                                      src={Cancel}
                                      alt=""
                                      onClick={() => {
                                        setFile(null);
                                        setImage(null);
                                      }}
                                    />{" "}
                                  </div>
                                )}
                                <div
                                  className="button position-relative uploadFile"
                                  style={{ marginTop: file ? "auto" : "0px" }}
                                >
                                  <span style={{ cursor: "pointer" }}>
                                    Upload Image
                                  </span>
                                  <input
                                    type="file"
                                    accept="image/jpeg, image/png, image/jpg"
                                    id="inputImage"
                                    className="notificationbtn"
                                    onChange={(e) => onImageUpload(e)}
                                  />
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-6"></div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-6 d-flex flex-column align-items-start">
                          <p className="to">Title</p>
                          <input
                            className="description"
                            onChange={(e) => setSubTitle(e.target.value)}
                            type="text"
                            placeholder="Enter title"
                          />
                        </div>
                        <div className="col-md-6 d-flex flex-column align-items-start">
                          <p className="to">Description</p>
                          <input
                            className="description"
                            onChange={(e) => setSubDescription(e.target.value)}
                            type="text"
                            placeholder="Enter Description"
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-start flex-column">
                          <p className="to">Link</p>
                          <input
                            className="link"
                            type="text"
                            placeholder="Enter Link"
                            onChange={(e) => setLink(e.target.value)}
                          />
                        </div>
                      </div>
                    </>
                  )}

                <div className="col-md-6 d-flex align-items-start flex-column"></div>

                  <button
                    className="mt-5"
                    onClick={onSubmit}
                    style={{ width: "30%", marginLeft: "50px" }}
                  >
                    Submit
                  </button>

               
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="F">
          {" "}
          <SpinnerCircular enabled={true} />
        </div>
      )}
    
    </div>
  );
}

export default Notification;
