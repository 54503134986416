import React from 'react'
import Pause from '../assets/pauseIcon.png'
import DeleteIcon from '../assets/deleteIcon.png'
import DefaultImage from "../assets/defaultImage.png"

type Props = {
  title: string
  subtitle: string
  Onclick: () => void
  item: any
  onVideo: (item:any) => void
  isDisabled?: boolean | any
}

function UploadedVideoCard({
  title,
  subtitle,
  item,
  Onclick,
  onVideo,
  isDisabled
}: Props): JSX.Element {
  console.log('item', item.thumbnail)
  return (
    <div className={`card container p-3 uploadedVideoCard ${isDisabled && "cardDisabled"}`}>
      <div className="col-md-4 thumbnailContainer">
        <div className="position-relative">
          <img src={item.thumbnail.includes("https") ? item.thumbnail : DefaultImage} alt="Thumbnail" className="w-100" style={{ borderRadius: "12px" }} />
          <div
            className="pauseContainer d-flex align-items-center justify-content-center"
            onClick={()=>onVideo(item)}
          >
            <img src={Pause} alt="Pause Icon" />
          </div>
        </div>
      </div>
      <div className="col-md-5 px-3 justify-content-center d-flex flex-column align-items-start">
        <h3>{title}</h3>
        <p>{subtitle}</p>
      </div>
      <div className="col-md-3 d-flex align-items-center justify-content-end">
      
        {localStorage.getItem('subRole') == 'super' ?
        <div className="iconContainer" onClick={Onclick}>
          <img src={DeleteIcon} alt="Delete" /> 
        </div>
        : ""}
      </div>
    </div>
  )
}

export default UploadedVideoCard
