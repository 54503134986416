import { useState } from "react";
import Cross from "../../assets/crossIcon.png";
import Button from "../Button/Button";
type Props = {
  onClose: () => void;
  modalPosition: number;
  onNext: () => void;
  onPrevious: () => void;
  setQuestion: (val: any) => void;
  addNewOption: () => void;
  answer: (val: any, index: number) => void;
  points: (val: any, index: number) => void;
  optionLength: number
  onFrequency: (val:any) => void
  weekDay: (val: any)=> void
};
function EditPromModal({ onClose, modalPosition, onNext, setQuestion, addNewOption, optionLength, answer, points, onFrequency, weekDay, onPrevious}: Props) {

  const [frequency, setFrequency] = useState<any>();
  
  const language =
    modalPosition == 2 ? "Hindi" : modalPosition == 3 ? "Marathi" : "";
  return (
    <div className="modalOverlay" id="modalOverlay">
      <div className="modalInner pb-4">
        <div className="modalHeader mb-4 d-flex align-items-center justify-content-between px-3 py-2 w-100">
          <p onClick={()=> console.warn()}> Edit Prom Data {modalPosition}</p>
          <img src={Cross} alt="Cross Icon" onClick={onClose} />
        </div>
        <div className="modalContent px-4">
          <p className="mb-2">Add {language} question</p>
          <input
            type="text"
            className="mb-4 px-3 w-100 py-1 addTitleInput"
            onChange={(e) => setQuestion(e.target.value)}
          />

          {Array(optionLength).fill(optionLength).map((val, index: number)=>{
return <div className="row">
<div className="col-md-5">
  <p className="mb-2">Add {language} Answer {index + 1}</p>
  <input
    type="text"
    className="mb-4 px-3 w-100 py-1 addTitleInput"
    placeholder="<120"
    onChange={(e)=> answer(e.target.value, index)}
  />
</div>
{modalPosition == 3 && (
  <div className="col-md-2">
    <p className="mb-2">Add Value</p>
    <input
      type="text"
      className="mb-4 px-3 w-100 py-1 addTitleInput"
      onChange={(e)=> points(e.target.value, index)}
    />
  </div>
)}

</div>
          }) }
          
         

          <div className="row mb-4"></div>
          {modalPosition == 1 && (
            <a className="addNewAnswer mb-3 d-block" onClick={()=>{

              addNewOption()
              }}>
              <span className="mx-1">+</span>ADD NEW ANSWER
            </a>
          )}
        </div>
        {modalPosition == 3 && (
          <div className="row">
            <div className="col-md-8">
              <div className="cc">
                <p>Frequency</p>
              </div>
              <select
                className="fr"
                onChange={(e) =>{ onFrequency(e.target.value); setFrequency(e.target.value)}}
              >
                <option>Monthly</option>
                <option>Weekly</option>
                <option>Half month</option>
              </select>
            </div>
            {frequency == "Weekly" && (
              <>
                <div className="col-md-4">
                  <div className="cc">
                    <p>Select Day</p>
                  </div>
                  <select
                    className="rf"
                    onChange={(e) => weekDay(e.target.value)}
                  >
                    <option>Sunday</option>
                    <option>Monday</option>
                    <option>Tuesday</option>
                    <option>Wednesday</option>
                    <option>Thrusday</option>
                    <option>Friday</option>
                    <option>Saturday</option>
                  </select>
                </div>
              </>
            )}
         
          </div>
        )}
        {modalPosition > 1 && <Button
          buttonText="Prev"
          isDarkBg
          className="save"
          onButtonClick={() => onPrevious() }
          style={{marginRight: "20%"}}
        />}
        <Button
          buttonText={modalPosition == 3 ? "Save" : "Next"}
          isDarkBg
          className="save"
          onButtonClick={() => onNext() }
        />
        
      </div>
    </div>
  );
}
export default EditPromModal;
