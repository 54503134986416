import React from "react";
import "./App.css";
import "./style/Public.css";
import "./components/Layout/Layout.css"
import "./components/Sidebar/Sidebar.css"
import "./components/Dropdown/Dropdown.css"
import "./components/AddAdminModal/AddAdminModal.css"
import Layout from "./components/Layout/Layout";

function App() {
  return (
    <div className="App">
      <Layout />
    </div>
  )
}

export default App;
