import { useState, useEffect, useMemo } from "react";
import Dropdown from "../components/Dropdown/Dropdown";
import FrameImage from "../assets/frameImage.png";
import Button from "../components/Button/Button";
import SearchIcon from "../assets/searchIcon.png";
import Thumbnail from "../assets/thumbnail.png";
import firebase from "../connection/firebase";
import { END_INDEX, START_INDEX } from "../constants/Constants";
import Cross from "../assets/crossIcon.png";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarItems from "../components/SidebarItems";

function IecContent() {
    const [userData, setUserData] = useState<any>([]);
    const [newCategoryDropdown, setNewCategoryDropdown] =
        useState<any>("Category");
    const [editCategoryDropdown, setEditCategoryDropdown] =
        useState<any>("Category");
    const [editFrequencyDropdown, setEditFrequencyDropdown] =
        useState<any>("Frequency");
    const [topicDropdownValue, setTopicDropdownValue] = useState<any>("Topic");
    const [topicTypeDropdownValue, setTopicTypeDropdownValue] =
        useState<any>("MNT");
    const [activityId, setActivityId] = useState<any>();
    const [newCategoryId, setNewCategoryId] = useState<number>();
    const [editCategoryId, setEditCategoryId] = useState<number>();
    const [editFrequencyId, setEditFrequencyId] = useState<number>();

    const [newImage, setNewImage] = useState<any>();
    const [editImage, setEditImage] = useState<any>();
    const [editableData, setEditableData] = useState<any>();
    const [newTitle, setNewTitle] = useState<any>();
    const [newTitleHindi, setNewTitleHindi] = useState<any>();
    const [newTitleMarathi, setNewTitleMarathi] = useState<any>();
    const [newDescription, setNewDescription] = useState<any>();
    const [newEnURL, setNewEnURL] = useState<any>();
    const [newHiURL, setNewHiURL] = useState<any>();
    const [newMrURL, setNewMrURL] = useState<any>();
    const [newHiDescription, setNewHiDescription] = useState<any>();
    const [newMrDescription, setNewMrDescription] = useState<any>();
    const [editableTitle, setEditableTitle] = useState<any>();
    const [editableDescriptionEn, setEditableDescriptionEn] = useState<any>();
    const [editableHindiDescription, setEditableHindiDescription] =
        useState<any>();
    const [editableMarathiDescription, setEditableMarathiDescription] =
        useState<any>();
    const [editableHindiTitle, setEditableHindiTitle] = useState<any>();
    const [editableMarathiTitle, setEditableMarathiTitle] = useState<any>();
    const [editableURL, setEditableURL] = useState<any>();
    const [editableMrURL, setEditableMrURL] = useState<any>();
    const [editableHiURL, setEditableHiURL] = useState<any>();
    const [isNewCategory, setIsNewCategory] = useState(false);
    const [isEditCategory, setIsEditCategory] = useState(false);
    const [isEditFrequency, setIsEditFrequency] = useState<number>();
    const [isTopic, setIsTopic] = useState(false);
    const [isTopicType, setIsTopicType] = useState<number>();
    const [lastIndex, setLastIndex] = useState(END_INDEX);
    const [newfile, setNewFile] = useState<any>();
    const [editFile, setEditFile] = useState<any>();
    const [imageUrl, setImageUrl] = useState("");
    const [categoryList, setCategoryList] = useState();
    const [tabs, setTabs] = useState<any>({
        0: true,
        1: false,
        2: false,
        3: false,
    });

    const defaultStateTabs = {
        0: false,
        1: false,
        2: false,
        3: false,
    };
    const [isTypeDropdown, setIsTypeDropdown] = useState(false);
    const [typeDropdownValue, setTypeDropdownValue] = useState("Select Type");

    const buttons = [
        {
            text: "All",
        },
        {
            text: "PDF",
        },
        {
            text: "Video",
        },
    ];

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("subRole") == undefined) {
            navigate("/");
        } else {
            if (localStorage.getItem("subRole") == "super") {
                let matchingRoute = SidebarItems.AdminItems.find(
                    (item) => item.route === location.pathname
                );
                if (matchingRoute == undefined)
                    navigate(SidebarItems?.AdminItems[0]?.route);
            } else if (
                localStorage.getItem("subRole") == "state" ||
                localStorage.getItem("subRole") == "district" ||
                localStorage.getItem("subRole") == "block"
            ) {
                let matchingRoute = SidebarItems.StateLevelItems.find(
                    (item) => item.route === location.pathname
                );
                if (matchingRoute == undefined)
                    navigate(SidebarItems?.StateLevelItems[0]?.route);
            } else if (localStorage.getItem("subRole") == "user") {
                let matchingRoute = SidebarItems.UserItems.find(
                    (item) => item.route === location.pathname
                );
                if (matchingRoute == undefined)
                    navigate(SidebarItems?.UserItems[0]?.route);
            } else {
                let matchingRoute = SidebarItems.WorkerItems.find(
                    (item) => item.route === location.pathname
                );
                if (matchingRoute == undefined)
                    navigate(SidebarItems?.WorkerItems[0]?.route);
            }
        }
    }, []);

    const getActivity = () => {
        fetch("https://maaswasthyasangini.com/Activity/getActivityIndex", {
            method: "POST",
            body: JSON.stringify({
                StartIndex: START_INDEX,
                EndIndex: "10",
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                let res = result.result[0].reverse();
                setUserData(res);
                console.log("get", res);
            })
            .catch((error) => console.log("error", error));
    };

    const getActivityOnPagination = () => {
        fetch("https://maaswasthyasangini.com/Activity/getActivityIndex", {
            method: "POST",
            body: JSON.stringify({
                StartIndex: lastIndex + START_INDEX,
                EndIndex: lastIndex + END_INDEX,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                let res = result.result[0].reverse();
                setUserData([...userData, ...res]);
                setLastIndex(lastIndex + END_INDEX);
            })
            .catch((error) => console.log("error", error));
    };

    useEffect(() => {
        getActivity();
        fetch("https://maaswasthyasangini.com//Activity/getActivityCategory")
            .then((response) => response.json())
            .then((data) => {
                console.log("cat: ", data.data);
                setCategoryList(data.data);
            });
    }, []);

    const upload = async (imageDetails: any, isFromEdit: boolean) => {
        if (imageDetails == null) return;
        const storage = firebase.storage();
        const storageRef = storage
            .ref(`/images/${imageDetails[0].name}`)
            .put(imageDetails[0]);
        storageRef.on(
            "state_changed",
            (snapShot) => {

                console.log(snapShot);
            },
            (err) => {
                console.log("Error: ", err);
            },
            () => {
                storage
                    .ref("images")
                    .child(imageDetails[0].name)
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                        console.log(fireBaseUrl);

                        setImageUrl(fireBaseUrl);
                    });
            }
        );
    };

    const editData = () => {

        fetch("https://maaswasthyasangini.com/Activity/UpdateActivity", {
            method: "PATCH",
            body: JSON.stringify({
                activity_id: activityId,

                video_url: editableURL,
                video_url_hi: editableHiURL,
                video_url_mr: editableMrURL,
                description_en: editableDescriptionEn,
                description_hi: editableHindiDescription,
                description_mr: editableMarathiDescription,

                category_id:
                    editCategoryDropdown != "Category"
                        ? editCategoryId?.toString()
                        : null,
                frequency:
                    editFrequencyDropdown != "Frequency" ? editFrequencyDropdown : null,
                image_url: imageUrl ? imageUrl : editableData.image_url,
                topic: topicDropdownValue,
                gdm_list: topicTypeDropdownValue,
                type: editableData.type,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                alert(result.msg);
                setEditableData(false);
                getActivity();
                setLastIndex(END_INDEX);
            })
            .catch((error) => alert(error));
    };

    const onEdit = () => {
        fetch("https://maaswasthyasangini.com/Resources/UpdateResources", {
            method: "PATCH",
            body: JSON.stringify({
                resource_image_url: imageUrl ? imageUrl : editableData.image_url,
                resource_heading_en: editableDescriptionEn,
                resource_content_en: editableURL,
                resource_content_hi: editableHiURL,
                resource_content_mr: editableMrURL,
                resource_heading_hi: editableHindiDescription,
                resource_heading_mr: editableMarathiDescription,
                resource_id: editableData.id,
                type: editableData.type,
                topic: topicDropdownValue,
                gdm_list: topicTypeDropdownValue ?? null,

            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log("res", result);
                alert("Content Updated Successfully");
                setEditableData(false);
                getActivity();
                setLastIndex(END_INDEX);
            })
            .catch((error) => console.log("error", error));
    };

    const clearCache = () => {
        setNewTitle("");
        setNewTitleHindi("");
        setNewTitleMarathi("");
        setNewEnURL("");
        setImageUrl("");
        setNewFile(Thumbnail);
        setNewHiURL("");
        setNewMrURL("");
        setNewDescription("");
        setTopicDropdownValue("Topic");
        setTopicTypeDropdownValue("MNT");
        setNewHiDescription("");
        setNewMrDescription("");
    };

    const onCreate = () => {
        let value = typeDropdownValue.replaceAll("(Document tab only)", "");
        fetch("https://maaswasthyasangini.com/Activity/CreateActivity", {
            method: "POST",
            body: JSON.stringify({
                image_url: imageUrl,
                description_en: newTitle,
                category_id: newCategoryId?.toString(),
                title: newTitle,
                title_hi: newTitleMarathi,
                title_mr: newTitleHindi,
                video_url: newEnURL,
                video_url_hi: newHiURL,
                video_url_mr: newMrURL,
                description_hi: newTitleMarathi,
                description_mr: newTitleHindi,

                type: value.toLowerCase(),
                frequency: "",
                topic: topicDropdownValue,
                gdm_list: topicTypeDropdownValue ?? null,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log("res", result);
                alert("Content Uploaded Successfully");
                getActivity();
                clearCache();
            })
            .catch((error) => console.log("error", error));
    };

    const onPdfCreate = () => {
        fetch("https://maaswasthyasangini.com/Resources/CreateResources", {
            method: "POST",
            body: JSON.stringify({
                resource_heading_en: newTitle,
                resource_heading_mr: newTitleMarathi,
                resource_content_en: newEnURL,
                resource_content_mr: newMrURL,
                resource_image_url: imageUrl,
                type: "pdf",
                resource_content_hi: newHiURL,
                resource_heading_hi: newTitleHindi,
                score: "",
                pregnancy_month: "",
                topic: topicDropdownValue.replaceAll("(Resource tab only)", ""),
                gdm_list: topicTypeDropdownValue ?? null,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log("res", result);
                alert("Content Uploaded Successfully");
                getActivity();
                clearCache();
            })
            .catch((error) => console.log("error", error));
    };

    const onActivityDelete = (item: any) => {
        fetch("https://maaswasthyasangini.com/activity/DeleteActivity", {
            method: "DELETE",
            body: JSON.stringify({
                ActivityId: item.id,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log("res", result);
                getActivity();
                setLastIndex(END_INDEX);
                alert("Deleted Successfully");
            })
            .catch((error) => console.log("error", error));
    };

    const onResourceDelete = (item: any) => {
        fetch("https://maaswasthyasangini.com/Resources/DeleteResources", {
            method: "DELETE",
            body: JSON.stringify({
                ResourceId: item.id,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((result) => {
                console.log("res", result);
                getActivity();
                setLastIndex(END_INDEX);
                alert("Deleted Successfully");
            })
            .catch((error) => console.log("error", error));
    };

    const onDelete = (item: any) => {
        console.log(item);
        if (window.confirm("Are you sure want to delete")) {
            if (item.type1 == "activity") {
                onActivityDelete(item);
            } else {
                onResourceDelete(item);
            }
        }
    };

    const tabSwitch = (index: any) => {
        setTabs({ ...defaultStateTabs, [index]: true });
    };

    const onClickCategory = (title: string, index: number) => {
        // console.log("Selected category", title, index);
        // console.log(categoryList);
        // setNewCategoryId(index + 1);
        setNewCategoryId(index);
        setNewCategoryDropdown(title);
        setIsNewCategory(false);
    };

    const onClickEditCategory = (title: string, index: number) => {
        // setEditCategoryId(index + 1);
        setEditCategoryId(index);
        setEditCategoryDropdown(title);
        setIsEditCategory(false);
    };

    const onClickEditFrequency = (title: string, index: number) => {
        setEditFrequencyId(index + 1);
        setEditFrequencyDropdown(title);
        setIsEditFrequency(0);
    };

    const onTopic = (title: string, index: number) => {
        setTopicDropdownValue(title);
        setIsTopic(false);
    };

    const onTopicType = (title: string, index: number) => {
        setTopicTypeDropdownValue(title);
        setIsTopicType(0);
    };

    const onTypeDropdownTwo = (title: string, index: number) => {
        setTypeDropdownValue(title);
        setIsTypeDropdown(false);
    };

    const onNewImageUpload = (val: any) => {
        if (val.target.files[0].size > 5 * 1024 * 1024) {
            alert("Please upload file less than 2mb");
        } else {
            const img = new Image();
            img.src = URL.createObjectURL(val.target.files[0]);
            img.onload = () => {
                if (Number(img.height / img.width) > 0.5 && Number(img.height / img.width) < 0.8) {
                    setNewFile(URL.createObjectURL(val.target.files[0]));
                    console.log("hsgddg", val.target.files[0].size);
                    setNewImage(val.target.files);
                    upload(val.target.files, false);
                } else {
                    alert("Please upload file with correct aspect ratio");
                }
                console.log(img.height);
                console.log(img.width);
            };
        }
    };

    const onEditImageUpload = (val: any) => {
        if (val.target.files[0].size > 5 * 1024 * 1024) {
            alert("Please upload file less than 2mb");
        } else {
            const img = new Image();
            img.src = URL.createObjectURL(val.target.files[0]);
            img.onload = () => {
                if (Number(img.height / img.width) > 0.5 && Number(img.height / img.width) < 0.8) {
                    setEditFile(URL.createObjectURL(val.target.files[0]));
                    setEditImage(val.target.files);
                    upload(val.target.files, true);
                } else {
                    alert("Please upload file with correct aspect ratio");
                }
            };
        }
    };

    const getPreviousValues = (item: any) => {
        console.log("items", item);
        setActivityId(item.id);
        setEditableTitle(item.title);
        setEditableHindiTitle(item.title_hi);
        setEditableMarathiTitle(item.title_mr);
        setEditableDescriptionEn(item.description_en);
        setEditableHindiDescription(item.description_hi);
        setEditableMarathiDescription(item.description_mr);
        let category =
            item.category_id == 4
                ? "Medication"
                : item.category_id == 3
                    ? "Health"
                    : item.category_id == 2
                        ? "Mothercare"
                        : "Diet & Excercise";
        setEditCategoryDropdown(category);
        setEditCategoryId(item.category_id);
        setEditableURL(item.video_url);
        setEditableHiURL(item.video_url_hi);
        setEditableMrURL(item.video_url_mr);
        setImageUrl(item.image_url);
        setTopicDropdownValue(item.topic);
    };

    return (
        <div className="contentMainContainer">
            <div className="container cardMainContainer">
                <div className="row w-100 mb-5">
                    <div className="col">
                        <div className="card uploadThumbnailCard p-4">
                            <div className="row mb-4">
                                <div className="col-md-4 d-flex justify-content-end align-items-start pe-0">
                                    <img
                                        src={newfile ?? Thumbnail}
                                        alt=""
                                        className="thumbnailImage"
                                    />
                                </div>
                                <div className="col-md-6 content d-flex align-items-start ms-3 justify-content-start flex-column">
                                    <p className="mb-2">Upload Thumbnail</p>
                                    <div className="button position-relative chooseFiles">
                                        <span>Choose file</span>
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/jpg"
                                            id="inputImage"
                                            className="uploadButton fileChoose"
                                            onChange={(e) => onNewImageUpload(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row typeContainer">
                                <div className="col-md-12 mb-3">
                                    <p className="mb-2 titleText">Select Type</p>
                                    <Dropdown
                                        className="frameDropdown videoDropdown"
                                        dropdownListClass="dropdownListItems"
                                        onMouseMove={() => { }}
                                        onDropDown={() => {
                                            setIsTypeDropdown(!isTypeDropdown);
                                        }}
                                        onDropdownList={(title: string, index: number) =>
                                            onTypeDropdownTwo(title, index)
                                        }
                                        dropdownValue={typeDropdownValue}
                                        isDropdownList={isTypeDropdown}
                                        dropDownIndex={1}
                                        dropdownItems={[
                                            { name: "Video (Document tab only)" },
                                            { name: "Youtube (Document tab only)" },
                                            { name: "PDF (Resource tab only)" },
                                        ]}
                                        isSingleDropdown
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="mb-2 titleText">Add English Title</p>
                                    <input
                                        type="text"
                                        placeholder={"Add title English"}
                                        className="mb-3 px-3 py-1 addTitleInput"
                                        onChange={(e) => {
                                            setNewTitle(e.target.value);
                                        }}
                                        value={newTitle ?? ""}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2 titleText">Add Hindi Title</p>
                                    <input
                                        type="text"
                                        placeholder={"Add title Hindi"}
                                        className="mb-3 px-3 py-1 addTitleInput"
                                        onChange={(e) => {
                                            setNewTitleHindi(e.target.value);
                                        }}
                                        value={newTitleHindi ?? ""}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2 titleText">Add Marathi Title</p>
                                    <input
                                        type="text"
                                        placeholder={"Add title Marathi"}
                                        className="mb-3 px-3 py-1 addTitleInput"
                                        onChange={(e) => {
                                            setNewTitleMarathi(e.target.value);
                                        }}
                                        value={newTitleMarathi ?? ""}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2 titleText">Add English Url</p>
                                    <input
                                        type="text"
                                        placeholder={"Add English Url"}
                                        className="mb-3 px-3 py-1 addTitleInput"
                                        onChange={(e) => {
                                            setNewEnURL(e.target.value);
                                        }}
                                        value={newEnURL ?? ""}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2 titleText">Add Hindi Url</p>
                                    <input
                                        type="text"
                                        placeholder={"Add Hindi Url"}
                                        className="mb-3 px-3 py-1 addTitleInput"
                                        onChange={(e) => {
                                            setNewHiURL(e.target.value);
                                        }}
                                        value={newHiURL ?? ""}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <p className="mb-2 titleText">Add Marathi Url</p>
                                    <input
                                        type="text"
                                        placeholder={"Add Marathi Url"}
                                        className="mb-3 px-3 py-1 addTitleInput"
                                        onChange={(e) => {
                                            setNewMrURL(e.target.value);
                                        }}
                                        value={newMrURL ?? ""}
                                    />
                                </div>

                                {typeDropdownValue != "PDF (Resource tab only)" && (
                                    <div className="col-md-6 mb-3">
                                        <p className="mb-2 titleText"> Select Category</p>
                                        <Dropdown
                                            className="frameDropdown"
                                            dropdownListClass="dropdownListItems"
                                            onMouseMove={() => { }}
                                            onDropDown={() => {
                                                setIsNewCategory(!isNewCategory);
                                            }}
                                            onDropdownList={(title: string, index: number) =>
                                                onClickCategory(title, index)
                                            }
                                            dropdownValue={newCategoryDropdown}
                                            isDropdownList={isNewCategory}
                                            dropDownIndex={0}
                                            dropdownItems={categoryList}
                                            isSingleDropdown
                                        />
                                    </div>
                                )}
                                <div className="col-md-6 mb-3">
                                    <p className="mb-2 titleText">Select Topic</p>
                                    <Dropdown
                                        className="frameDropdown"
                                        dropdownListClass="dropdownListItems"
                                        onMouseMove={() => { }}
                                        onDropDown={() => {
                                            setIsTopic(!isTopic);
                                        }}
                                        onDropdownList={(title: string, index: number) =>
                                            onTopic(title, index)
                                        }
                                        dropdownValue={topicDropdownValue}
                                        isDropdownList={isTopic}
                                        dropDownIndex={0}
                                        dropdownItems={[
                                            { name: "all" },
                                            { name: "gdm" },
                                            { name: "gdm_ne" },
                                            { name: "untested" },
                                            { name: "guest" },
                                        ]}
                                        isSingleDropdown
                                    />
                                </div>
                                {topicDropdownValue === "gdm" && (
                                    <div className="col-md-6 mb-3">
                                        <p className="mb-2 titleText">Select Gdm Type</p>
                                        <Dropdown
                                            className="frameDropdown"
                                            dropdownListClass="dropdownListItems"
                                            onMouseMove={() => { }}
                                            onDropDown={() => {
                                                setIsTopicType(1);
                                            }}
                                            onDropdownList={(title: string, index: number) =>
                                                onTopicType(title, index)
                                            }
                                            dropdownValue={topicTypeDropdownValue}
                                            isDropdownList={isTopicType}
                                            dropDownIndex={1}
                                            dropdownItems={[
                                                { name: "all" },
                                                { name: "mnt" },
                                                { name: "exercise" },
                                                { name: "metformin" },
                                                { name: "insulin" },
                                            ]}
                                        />
                                    </div>
                                )}
                            </div>
                            <Button
                                buttonText={"Upload Content"}
                                isDarkBg={true}
                                className="mt-4 mb-3 uploadContentBtn"
                                onButtonClick={
                                    typeDropdownValue === "PDF (Resource tab only)"
                                        ? onPdfCreate
                                        : onCreate
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-md-5 position-relative">
                        <img src={SearchIcon} alt="Search Icon" className="searchIcon" />
                        <input
                            type="text"
                            placeholder="Search for file"
                            className="searchBar search_bar pe-5"
                        />
                    </div>
                    <div className="col-md-7 d-flex align-items-center justify-content-end">
                        <p className="me-3 filter">Filter</p>
                        <div className="btnContainer d-flex">
                            {buttons.map((item, index) => {
                                return (
                                    <Button
                                        buttonText={item.text}
                                        key={index}
                                        onButtonClick={() => tabSwitch(index)}
                                        className="ms-3 filterButton"
                                        withBorder={tabs[index] == true ? false : true}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
                {editableData && (
                    <div className="container mb-3 pe-0">
                        <div className="row">
                            <div className="modalOverlay" id="modalOverlay">
                                <div className="card modalInner frameCard editCard pb-3">
                                    <div className="modalHeader mb-4 d-flex align-items-center justify-content-between px-3 py-2 w-100">
                                        <p>Upload Content</p>
                                        <img
                                            src={Cross}
                                            alt="Cross Icon"
                                            onClick={() => setEditableData(false)}
                                        />
                                    </div>
                                    <div className="modalContent px-4">
                                        <div className="row mb-3">
                                            <div className="col-lg-6 col-md-12 p-0 dImage">
                                                <img
                                                    src={
                                                        editFile
                                                            ? editFile
                                                            : editableData.image_url
                                                                ? editableData.image_url
                                                                : FrameImage
                                                    }
                                                />
                                            </div>
                                            <div className="col-lg-6 col-md-12 aboutImage">
                                                <p>png, Jpeg, Jpg</p>
                                                <span>Max file size - 20 kb</span>
                                                <div className="button position-relative chooseFiles">
                                                    <span style={{ color: "#fff" }}>Choose file</span>
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/png, image/jpg"
                                                        id="inputImage"
                                                        className="uploadButton fileChoose"
                                                        onChange={(e) => onEditImageUpload(e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 p-0">
                                                <p className="mb-2 titleText">Add English Title</p>
                                                <input
                                                    type="text"
                                                    placeholder={editableData.title ?? "Add title"}
                                                    className="px-3 mb-3 w-100 py-1 addTitleInput addTitle"
                                                    onChange={(e) =>
                                                        setEditableDescriptionEn(e.target.value)
                                                    }
                                                    value={editableDescriptionEn ?? ""}
                                                />
                                            </div>
                                            <div className="col-md-12 p-0">
                                                <p className="mb-2 titleText">Add Hindi Title</p>
                                                <input
                                                    type="text"
                                                    placeholder={
                                                        editableData.title_hi ?? "Add Hindi title"
                                                    }
                                                    className="px-3 mb-3 w-100 py-1 addTitleInput addTitle"
                                                    onChange={(e) =>
                                                        setEditableHindiDescription(e.target.value)
                                                    }
                                                    value={editableHindiDescription ?? ""}
                                                />
                                            </div>
                                            <div className="col-md-12 p-0">
                                                <p className="mb-2 titleText">Add Marathi Title</p>
                                                <input
                                                    type="text"
                                                    placeholder={
                                                        editableData.title_mr ?? "Add Marathi title"
                                                    }
                                                    className="px-3 mb-3 w-100 py-1 addTitleInput addTitle"
                                                    onChange={(e) =>
                                                        setEditableMarathiDescription(e.target.value)
                                                    }
                                                    value={editableMarathiDescription ?? ""}
                                                />
                                            </div>

                                        </div>

                                        <div className="row mb-3">
                                            <p className="mb-2 titleText p-0">Add English Url</p>
                                            <input
                                                type="text"
                                                placeholder={editableData.video_url ?? "Add url"}
                                                className="px-3 w-100 py-1 addTitleInput addTitle"
                                                onChange={(e) => setEditableURL(e.target.value)}
                                                value={editableURL ?? ""}
                                            />
                                        </div>
                                        <div className="row mb-3">
                                            <p className="mb-2 titleText p-0">Add Hindi Url</p>
                                            <input
                                                type="text"
                                                placeholder={editableData.video_url_hi ?? "Add url"}
                                                className="px-3 w-100 py-1 addTitleInput addTitle"
                                                onChange={(e) => setEditableHiURL(e.target.value)}
                                                value={editableHiURL ?? ""}
                                            />
                                        </div>
                                        <div className="row mb-3">
                                            <p className="mb-2 titleText p-0">Add Marathi Url</p>
                                            <input
                                                type="text"
                                                placeholder={editableData.video_url_mr ?? "Add url"}
                                                className="px-3 w-100 py-1 addTitleInput addTitle"
                                                onChange={(e) => setEditableMrURL(e.target.value)}
                                                value={editableMrURL ?? ""}
                                            />
                                        </div>

                                        {editableData.type != "pdf" && (

                                            <div className="row mb-3">
                                                <div className="col p-0">
                                                    <p className="mb-2 titleText">Select Category</p>
                                                    <Dropdown
                                                        className="frameDropdown"
                                                        dropdownListClass="dropdownListItems"
                                                        onMouseMove={() => { }}
                                                        onDropDown={() => {
                                                            setIsEditCategory(!isEditCategory);
                                                        }}
                                                        onDropdownList={(title: string, index: number) =>
                                                            onClickEditCategory(title, index)
                                                        }
                                                        dropdownValue={editCategoryDropdown}
                                                        isDropdownList={isEditCategory}
                                                        dropDownIndex={0}
                                                        dropdownItems={categoryList}
                                                        isSingleDropdown
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        <div className="row">
                                            <div className="col-md-12 p-0 mb-3">
                                                <p className="mb-2 titleText">Select Topic</p>
                                                <Dropdown
                                                    className="frameDropdown"
                                                    dropdownListClass="dropdownListItems"
                                                    onMouseMove={() => { }}
                                                    onDropDown={() => {
                                                        setIsTopic(!isTopic);
                                                    }}
                                                    onDropdownList={(title: string, index: number) =>
                                                        onTopic(title, index)
                                                    }
                                                    dropdownValue={topicDropdownValue}
                                                    isDropdownList={isTopic}
                                                    dropDownIndex={0}
                                                    dropdownItems={[
                                                        { name: "all" },
                                                        { name: "gdm" },
                                                        { name: "gdm_ne" },
                                                        { name: "untested" },
                                                        { name: "guest" },
                                                    ]}
                                                    isSingleDropdown
                                                />
                                            </div>
                                            {topicDropdownValue === "gdm" && (
                                                <div className="col-md-12 p-0 mb-3">
                                                    <p className="mb-2 titleText">Select Gdm Type</p>
                                                    <Dropdown
                                                        className="frameDropdown"
                                                        dropdownListClass="dropdownListItems"
                                                        onMouseMove={() => { }}
                                                        onDropDown={() => {
                                                            setIsTopicType(1);
                                                        }}
                                                        onDropdownList={(title: string, index: number) =>
                                                            onTopicType(title, index)
                                                        }
                                                        dropdownValue={topicTypeDropdownValue}
                                                        isDropdownList={isTopicType}
                                                        dropDownIndex={1}
                                                        dropdownItems={[
                                                            { name: "all" },
                                                            { name: "mnt" },
                                                            { name: "exercise" },
                                                            { name: "metformin" },
                                                            { name: "insulin" },
                                                        ]}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className="col-md-6 m-auto">
                                            <Button
                                                buttonText={"Save"}
                                                className="saveButton"
                                                onButtonClick={
                                                    editableData.type1 == "activity" ? editData : onEdit
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {tabs[0] && (
                    <div className="frameCardMain">
                        {userData &&
                            userData.map((item: any, index: any) => {
                                // console.log("itemvido", item);
                                return (
                                    <div className="container mb-3" key={index}>
                                        <div className="row">
                                            <div className="card frameCard py-3">
                                                <div className="row d-flex align-items-center">
                                                    <div className="col-md-6">
                                                        <div className="row d-flex align-items-center">
                                                            <div className="col-md-6 col-lg-4">
                                                                <img
                                                                    src={item.image_url}
                                                                    className="frameImage"
                                                                />
                                                            </div>
                                                            <div className="col-md-8 col-lg-8">
                                                                <p className="frameCardTitle">
                                                                    {item.type1 == "activity"
                                                                        ? item.description_en
                                                                        : item.title}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row d-flex align-items-center justify-content-end">
                                                            <div className="col-md-4 col-lg-3">
                                                                <p className="dots">{item.type}</p>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <a
                                                                    onClick={() => {
                                                                        setEditCategoryDropdown(
                                                                            item.en ?? "Category"
                                                                        );
                                                                        setEditableData(item);
                                                                        getPreviousValues(item);
                                                                    }}
                                                                    className="btn btn-lg d-flex align-items-center justify-content-center editButton"
                                                                >
                                                                    Edit Data
                                                                </a>{" "}
                                                            </div>
                                                            <div className="col-md-3">
                                                                <i
                                                                    className="fa fa-trash-o deleteIcon"
                                                                    aria-hidden="true"
                                                                    onClick={() => onDelete(item)}
                                                                ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <a className="viewMoreBtn mt-5" onClick={getActivityOnPagination}>
                            View More
                        </a>
                    </div>
                )}
                {tabs[1] && (
                    <div className="frameCardMain">
                        {userData &&
                            userData.map((item: any, index: any) => {
                                if (item.type === "pdf") {
                                    return (
                                        <div className="container mb-3" key={index}>
                                            <div className="row">
                                                <div className="card frameCard py-3">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-6">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-6 col-lg-4">
                                                                    <img
                                                                        src={item.image_url}
                                                                        className="frameImage"
                                                                    />
                                                                </div>
                                                                <div className="col-md-8 col-lg-8">
                                                                    <p className="frameCardTitle">
                                                                        {item.description_en}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row d-flex align-items-center justify-content-end">
                                                                <div className="col-md-4 col-lg-3">
                                                                    <p className="dots">{item.type}</p>
                                                                </div>
                                                                <div
                                                                    className="col-md-3"
                                                                    onClick={() => {
                                                                        setEditCategoryDropdown(
                                                                            item.en ?? "Category"
                                                                        );
                                                                        setEditableData(item);
                                                                        getPreviousValues(item);
                                                                        console.log("itr", item);
                                                                    }}
                                                                >
                                                                    <a className="btn btn-lg d-flex align-items-center justify-content-center editButton">
                                                                        Edit Data
                                                                    </a>{" "}
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <i
                                                                        className="fa fa-trash-o deleteIcon"
                                                                        aria-hidden="true"
                                                                        onClick={() => onDelete(item)}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        <a className="viewMoreBtn mt-5">View More</a>
                    </div>
                )}
                {tabs[2] && (
                    <div className="frameCardMain">
                        {userData &&
                            userData.map((item: any, index: any) => {
                                console.log("item.vidoe", item);
                                if (item.type !== "pdf") {
                                    return (
                                        <div className="container mb-3" key={index}>
                                            <div className="row">
                                                <div className="card frameCard py-3">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-md-6">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-md-6 col-lg-4">
                                                                    <img
                                                                        src={item.image_url}
                                                                        className="frameImage"
                                                                    />
                                                                </div>
                                                                <div className="col-md-8 col-lg-8">
                                                                    <p className="frameCardTitle">
                                                                        {item.description_en}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="row d-flex align-items-center justify-content-end">
                                                                <div className="col-md-4 col-lg-3">
                                                                    <p className="dots">{item.type}</p>
                                                                </div>
                                                                <div
                                                                    className="col-md-3"
                                                                    onClick={() => {
                                                                        setEditCategoryDropdown(
                                                                            item.en ?? "Category"
                                                                        );
                                                                        setEditableData(item);
                                                                        getPreviousValues(item);
                                                                    }}
                                                                >
                                                                    <a className="btn btn-lg d-flex align-items-center justify-content-center editButton">
                                                                        Edit Data
                                                                    </a>{" "}
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <i
                                                                        className="fa fa-trash-o deleteIcon"
                                                                        aria-hidden="true"
                                                                        onClick={() => onDelete(item)}
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                        <a className="viewMoreBtn mt-5">View More</a>
                    </div>
                )}
            </div>
        </div>
    );
}

export default IecContent;
