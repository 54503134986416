import { useEffect, useState } from "react";
import Button from "../components/Button/Button";

import Dropdown from "../components/Dropdown/Dropdown";
import firebase from "firebase";
import CommonModal from "../components/CommonModal";
import "froala-editor/js/plugins.pkgd.min.js";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import { SpinnerCircular } from "spinners-react";
import FroalaEditor from "react-froala-wysiwyg";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarItems from "../components/SidebarItems";
const defaultContent = `<div id="demoText"></div>`;
function Communication() {
  const cards = [
    {
      id: 1,
      title: "Title will be written",
      subtitle:
        " Body text lorem ipsum Body text lorem ipsum Body text lorem ipsum Body text lorem",
    },
    {
      id: 2,
      title: "Title will be written",
      subtitle:
        " Body text lorem ipsum Body text lorem ipsum Body text lorem ipsum Body text lorem",
    },
  ];
  const subtitle =
    "rediabetes is when your blood sugar is high, but not high enough to be classified as diabetes.Your body usually manages your blood sugar levels by producing insulin, a hormone that allows your cells to use the circulating sugar in your blood. As such, insulin is the most important regulator of blood sugar levels (1Trusted Source).However, multiple factors can impair blood sugar management and lead to hyperglycemia.Internal causes for high blood sugar include when your liver produces too much glucose, ";
  const [isRecipients, setIsRecipients] = useState(false);
  const [recipientsDropdownValue, setRecipientsDropdownValue] =
    useState("Select Category");
  const [isPlatformDropdown, setIsPlatformDropdown] = useState(false);
  const [platformDropdownValue, setPlatformDropdownValue] = useState("None");
  const [alertData, setAlertData] = useState<any>([]);
  const [isActive, setIsActive] = useState<any>(cards);
  const [isPreviewModal, setIsPreviewModal] = useState(false);
  const [newFile, setNewFile] = useState<any>();
  const [newImage, setNewImage] = useState<any>();
  const [imageUrl, setImageUrl] = useState("");
  const [titleInputValue, setTitleInputValue] = useState("");
  const [subtitleInputValue, setSubtitleInputValue] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [bodyInputValue, setBodyInputValue] = useState("");
  const [generateAlert, setGenerateAlert] = useState<any>();
  const [isShowTypeDropdown, setIsShowTypeDropdown] = useState(false);
  const [isLoader, setIsLoader] = useState(false)
  const [typeDropdownValue, setTypeDropDownValue] =
    useState("Choose Recipients");

    const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("subRole") == undefined) {
      navigate("/")
    }
    else {
      if (localStorage.getItem("subRole") == "super") {
        let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
        let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == 'user') {
        let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
      }
      else {
        let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
      }
    }
  }, [])

 
  const onPlatformDropdown = (title: string) => {
    setPlatformDropdownValue(title);
    setIsPlatformDropdown(false);
  };
  const onTypeDropdown = (title: string) => {
    setTypeDropDownValue(title);
    setIsShowTypeDropdown(false);
  };

  const addBorder = (index: number) => {
    if (platformDropdownValue === "None") {
      alert("Please choose platform first");
      return;
    }
    const newArray = [...isActive];
    newArray.forEach((element) => {
      if (newArray[index].id === element.id) {
        newArray[index].isDropDownVisible = true;
      } else {
        element.isDropDownVisible = false;
      }
    });
    setIsActive(newArray);
  };

  const upload = async (imageDetails: any, isFromEdit: boolean) => {
    if (imageDetails == null) return;
    const storage = firebase.storage();
    const storageRef = storage
      .ref(`/images/${imageDetails[0].name}`)
      .put(imageDetails[0]);
    storageRef.on(
      "state_changed",
      (snapShot) => {
        console.log(snapShot);
      },
      (err) => {
        console.log("Error: ", err);
      },
      () => {
        storage
          .ref("images")
          .child(imageDetails[0].name)
          .getDownloadURL()
          .then((fireBaseUrl) => {
            console.log(fireBaseUrl);
            setImageUrl(fireBaseUrl);
          });
      }
    );
  };

  const onNewImageUpload = (val: any) => {
    if (val.target.files[0].size > 5*1024*1024) {
      alert("Please upload file less than 2mb");
    }else{
      const img = new Image();
      img.src = URL.createObjectURL(val.target.files[0]);
     
    setNewFile(URL.createObjectURL(val.target.files[0]));
    upload(val.target.files, false);
      
    }
  };

  const onImageUpload = (val: any) => {
    if (val.target.files[0].size > 5*1024*1024) {
      alert("Please upload file less than 2mb");
    }else{
      const img = new Image();
      img.src = URL.createObjectURL(val.target.files[0]);
     
    setNewImage(URL.createObjectURL(val.target.files[0]));
    upload(val.target.files, false);
 
}
  };

  const getAlerts = () => {
    setIsLoader(true)
    firebase
      .app()
      .functions("asia-south1")
      .httpsCallable("getAlertsLog")({})
      .then((response) => {
        let val = Object.entries(response.data).map(([text, value]) => {
          let questions: any = value;
          questions.promId = text;
          return questions;
        });
        let sortedList = val.sort((a, b) => new Date(a.timestamp).getDate() - new Date(b.timestamp).getDate());
        let reverseList = sortedList.reverse()
        setAlertData(reverseList);
        setIsLoader(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoader(false)
      })
  };

  const onCreateAlert = () => {
    firebase
      .app()
      .functions("asia-south1")
      .httpsCallable("createAlert")({
        title: titleInputValue,
        body: document.getElementById("demoText")?.innerText,
        footer: "",
        images: newFile ?? "",
        videos: "",
        topic: typeDropdownValue ?? "Send to all",
        type: platformDropdownValue.toUpperCase(),
        template: {
          title: titleInput,
          body: bodyInputValue,
        },
      })
      .then(async (response) => {
        console.log("aa", response.data);
        setGenerateAlert(response.data);
      });
  };

  const onSendAlert = () => {
    firebase
      .app()
      .functions("asia-south1")
      .httpsCallable("sendAlert")({
        alertId: generateAlert.alertId,
        dynamicLink: generateAlert.dynamicLink,
        topic: typeDropdownValue,
        type: platformDropdownValue.toUpperCase(),
        template: {
          title: titleInput,
          body: bodyInputValue,
        },
      })
      .then(async (response) => {
        console.log(response);
        alert("Alert Sent");
        getAlerts();
        setGenerateAlert([]);
        setTitleInput("");
        setBodyInputValue("");
        setTypeDropDownValue("Choose Recipients");
        setTitleInputValue("");
        setSubtitleInputValue("");
        setNewFile("");
        setNewImage("");
      });
  };

  const onModelChange = (model: any) => {
    let edData = model;
    let elem = document.createElement("div");
    elem.innerHTML = edData;
    console.log(elem);
  };


  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <div className="mb-5">
      <div className="container mt-5">
        <div className="row d-flex">
          <div className="col-md-8">
            <div className="card py-5 px-3 sendNotificationCard">
              <div className="row">
                <div className="row mb-4">
                  <div className="col-md-3 d-flex justify-content-start">
                    <p>Platform:</p>
                  </div>
                  <div className="col-md-9 d-flex justify-content-between platformDropdownContainer">
                    <div className="col-md-6">
                      <Dropdown
                        className="frameDropdown platformDropdown"
                        dropdownListClass="dropdownListItems"
                        onMouseMove={() => { }}
                        onDropDown={() => {
                          setIsPlatformDropdown(!isPlatformDropdown);
                        }}
                        onDropdownList={(title: string, ind: number) =>
                          onPlatformDropdown(title)
                        }
                        dropdownValue={platformDropdownValue}
                        isDropdownList={isPlatformDropdown}
                        dropDownIndex={0}
                        dropdownItems={[
                          { name: "Whatsapp" },
                          { name: "SMS" },
                          { name: "Notification" },
                        ]}
                        isSingleDropdown
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3 d-flex justify-content-start"></div>
                  <div className="col-md-9">
                    <p className="selectTemplate mb-3">Select Template</p>
                    <div className="row">
                      {isActive.map((item: any, index: number) => {
                        if (
                          platformDropdownValue === "SMS"
                            ? index < 1
                            : index < 2
                        ) {
                          return (
                            <div className="col-md-6 d-flex">
                              <div
                                className={`card templateCard p-3 ${item.isDropDownVisible ? "activeCard" : ""
                                  }`}
                                onClick={() => addBorder(index)}
                              >
                                <div className="innerCard mb-2">
                                  {index === 1 && (
                                    <div className="image p-2 mb-2">Image</div>
                                  )}
                                  <p className="title">{item.title}</p>
                                  <span className="subTitle">
                                    {item.subtitle}
                                  </span>
                                </div>
                                <p className="selectTemplate">
                                  Template {index + 1}
                                </p>
                              </div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-3 d-flex justify-content-start">
                    <p>Content:</p>
                  </div>
                  <div className="col-md-9 d-flex align-items-start flex-column">
                    <input
                      type="text"
                      className="titleInput w-100 px-4 py-2 mb-3"
                      placeholder="Title will be written"
                      onChange={(e) => setTitleInput(e.target.value)}
                    />
                    <input
                      type="text"
                      className="titleInput w-100 px-4 py-2 mb-3"
                      placeholder="Body text"
                      onChange={(e) => setBodyInputValue(e.target.value)}
                    />
                    <div className="uploadImageContainer uploadImage d-flex align-items-center justify-content-between addTitleInput mb-3 px-3">
                      <input
                        type="file"
                        className="uploadImageInput titleInput"
                        placeholder="Add Image"
                        accept="image/jpeg, image/png, image/jpg"
                        id="inputImage"
                        onChange={(e) => onImageUpload(e)}
                      />
                      {newImage ? <img src={newImage} alt="" /> : <p>Image</p>}
                      <Button buttonText="Upload image" />
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-md-3 d-flex justify-content-start">
                    <p>Recipients:</p>
                  </div>
                  <div className="col-md-9 d-flex justify-content-between platformDropdownContainer">
                    <div className="col-md-6">
                      <Dropdown
                        className="frameDropdown platformDropdown"
                        dropdownListClass="dropdownListItems"
                        onMouseMove={() => { }}
                        onDropDown={() => {
                          setIsShowTypeDropdown(!isShowTypeDropdown);
                        }}
                        onDropdownList={(title: string, ind: number) =>
                          onTypeDropdown(title)
                        }
                        dropdownValue={typeDropdownValue}
                        isDropdownList={isShowTypeDropdown}
                        dropDownIndex={0}
                        dropdownItems={[
                          { name: "Send to all" },
                          { name: "GDM +ve" },
                          { name: "GDM -ve" },
                          { name: "Not Tested" },
                          { name: "Guest Users" },
                        ]}
                        isSingleDropdown
                      />
                    </div>
                  </div>
                </div>
                <div className="row mb-5">
                  <div className="col-md-3"></div>
                  <div className="col-md-9 d-flex flex-column align-items-start">
                    <div className="card w-100 updateContentCard p-3 mb-4">
                      <h5 className="mb-3">Update Content</h5>
                      <input
                        type="text"
                        className="addTitleInput mb-3 px-3"
                        placeholder="Add Title"
                        onChange={(e) => {
                          console.log("title ", e.target.value);
                          setTitleInputValue(e.target.value);
                        }}
                      />
                     <FroalaEditor
  model={defaultContent}
  onModelChange={onModelChange}
  config={{
    attribution: false,
    toolbarButtons: {
      moreText: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'strikeThrough',
          'subscript',
          'superscript',
          'fontFamily',
          'fontSize',
          'textColor',
          'backgroundColor',
          'inlineClass',
          'inlineStyle',
          'clearFormatting',
        ],
        align: 'left',
        buttonsVisible: 3,
      },
      moreParagraph: {
        buttons: [
          'alignLeft',
          'alignCenter',
          'formatOLSimple',
          'alignRight',
          'alignJustify',
          'formatOL',
          'formatUL',
          'paragraphFormat',
          'paragraphStyle',
          'lineHeight',
          'outdent',
          'indent',
          'quote',
        ],
        align: 'left',
        buttonsVisible: 3,
      },
      moreRich: {
        buttons: [
          'insertLink',
          'insertImage',
          'insertVideo',
          'insertTable',
          'emoticons',
          'fontAwesome',
          'specialCharacters',
          'embedly',
          'insertFile',
          'insertHR',
        ],
        align: 'left',
        buttonsVisible: 3,
      },
      moreMisc: {
        buttons: [
          'undo',
          'redo',
          'fullscreen',
          'print',
          'getPDF',
          'spellChecker',
          'selectAll',
          'html',
          'help',
        ],
        align: 'right',
        buttonsVisible: 2,
      },
    },
  }}
/>

                      <div className="uploadImageContainer d-flex align-items-center justify-content-between addTitleInput mb-3 px-3">
                        <input
                          type="file"
                          className="uploadImageInput"
                          placeholder="Add Image"
                          accept="image/jpeg, image/png, image/jpg"
                          id="inputImage"
                          onChange={(e) => onNewImageUpload(e)}
                        />
                        {newFile ? <img src={newFile} alt="" /> : <p>Image</p>}
                        <Button buttonText="Upload image" />
                      </div>
                      <Button
                        buttonText="Preview"
                        isDarkBg
                        className="previewButton"
                        onButtonClick={() => setIsPreviewModal(true)}
                        isDisabled={
                          titleInputValue.length > 0
                            ? false
                            : true
                        }
                      />
                      {isPreviewModal && (
                        <CommonModal
                          heading={"Alert"}
                          onClose={() => setIsPreviewModal(false)}
                          title={
                            titleInputValue ??
                            "Reduce blood sugar with diabetic diet"
                          }
                          subtitle={document.getElementById("demoText")?.innerText ?? ""}
                          src={newFile}
                        />
                      )}
                    </div>
                    <Button
                      buttonText={"Generate Deep link"}
                      className="mb-4 deepLinkButton"
                      isDarkBg
                      onButtonClick={onCreateAlert}
                      isDisabled={
                        titleInputValue.length > 0
                          ? false
                          : true
                      }
                    />
                    <input
                      type="text"
                      disabled
                      className="addTitleInput px-3"
                      placeholder={
                        generateAlert ? generateAlert.dynamicLink : ""
                      }
                    />
                  </div>
                </div>
               
                <div className="row d-flex justify-content-center">
                  <div className="col-md-3 d-flex justify-content-start"></div>
                  <div className="col-md-9 d-flex">
                    <div className="row w-100">
                      <div className="col-md-6">
                        <Button
                          buttonText={"Send Now"}
                          isDarkBg
                          className="scheduleButton"
                          onButtonClick={onSendAlert}
                          isDisabled={
                            generateAlert && generateAlert.dynamicLink
                              ? false
                              : true
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card notificationCard pb-4">
              <div className="modalHeader d-flex align-items-center justify-content-between px-3 py-2 w-100">
                <p>Sent Messages</p>
              </div>
              {!isLoader ? <div className="row">
                {alertData &&
                  alertData.map((item: any, index: number) => {
                    console.log("ite", item)
                    const now = new Date(item.timestamp);
                    const date = now.toLocaleDateString();
                    const time = now.toLocaleTimeString();
                    return (
                      <div
                        className="d-flex aboutBeneficiaryContainer py-4"
                        key={index}
                      >
                        <div className="col-md-4">
                          <p className="aboutBeneficiary">
                            {item.medium} <br />
                            At {time ?? ""} <br />
                            ON {date ?? ""}
                          </p>
                        </div>
                        <div className="col-md-8 ps-2">
                          <h4 className="mb-1">{item.title}</h4>
                          <p className="subtitle">{item.description}</p>
                        </div>
                      </div>
                    );
                  })}
              </div> : <div className="loaderContainer">
                {" "}
                <SpinnerCircular enabled={true} />
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Communication;
