import React, { useEffect } from "react";
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarItems from "../SidebarItems";
import "../../style/Public.css";
import ArrowRight from "../../assets/arrowRight.png";
import LogoutIcon from "../../assets/logoutIcon.png";
import Firebase from "../../connection/firebase"

function SidebarComponent() {
  const { toggleSidebar } = useProSidebar();
  let location = useLocation();
  let navigate = useNavigate();

  const onLogout = () => {
    localStorage.clear();
    navigate("/")
  }

  useEffect(() => {
    Firebase.auth().onAuthStateChanged((user) => {
      console.log(user)
      if (!user){
        onLogout()
      }
    });
  }, [])

  const MenuItemComponent = ({ item, index }: { item: any, index: number }) => {
    return (
      <MenuItem
        className={
          location.pathname === item.route
            ? "activeClass"
            : "menuItem"
        }
        onClick={() => navigate(item.route)}
        key={index}
      >
        <img src={item.icon} alt="" className="icon" />
        {item.name}
        {location.pathname === item.route ? (
          <img
            src={ArrowRight}
            alt=""
            className="activeIcon ms-4"
          />
        ) : (
          ""
        )}
      </MenuItem>
    )
  }

  if (!localStorage.getItem("subRole")) navigate("/")
  return (
    <div style={{ display: "flex", height: "100%" }} className="min-vh-100">
      <i className="fa fa-bars bars" onClick={() => toggleSidebar()} aria-hidden="true"></i>
      <Sidebar className="pt-5 text-center" breakPoint="md">
        <Menu>
          <MenuItem className="userNameMain">
            <div className="d-flex justify-content-center">
              <p className="admin">{localStorage.getItem("fullName")?.substring(0, 1)}</p>
              <div className="mx-3 userNameContainer">
                <h4>{localStorage.getItem("fullName")}</h4>
                <p>{localStorage.getItem("subRole")} level Admin</p>
              </div>
            </div>
          </MenuItem>
          {localStorage.getItem("subRole") == "super"
            ? SidebarItems.AdminItems.map((item, index) => {
              return (<MenuItemComponent item={item} index={index} />);
            })
            : localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block"
              ? SidebarItems.StateLevelItems.map((item, index) => {
                return (<MenuItemComponent item={item} index={index} />);
              })
              : localStorage.getItem("subRole") == 'user'
                ? SidebarItems.UserItems.map((item, index) => {
                  return (<MenuItemComponent item={item} index={index} />);
                })
                : SidebarItems.WorkerItems.map((item, index) => {
                  return (<MenuItemComponent item={item} index={index} />);
                })
          }
          <MenuItem className="logoutButton" onClick={onLogout}>
            <img
              src={LogoutIcon}
              alt=""
              className="me-3"
            />
            Log out
          </MenuItem>
        </Menu>
      </Sidebar>
    </div>
  );
}

export default SidebarComponent;
