import React, { useEffect, useState } from 'react'
import SearchIcon from '../assets/searchIconDark.png'
import Button from '../components/Button/Button'
import ArrowDownIcon from '../assets/arrowDownDark.png'
// @ts-ignore
import FileUploadProgress from 'react-fileupload-progress'
import ProgressBar from '@ramonak/react-progress-bar'
import firebase, { auth, firestore } from '../connection/firebase'
import * as XLSX from 'xlsx'
import { SpinnerCircular } from 'spinners-react'
import axios from 'axios'
import moment from 'moment'
import SidebarItems from '../components/SidebarItems'
import { useLocation, useNavigate } from 'react-router-dom'
import { error } from 'console'

function PatientDirectory() {
    const [file, setFile] = useState<any>()
    const [fileUrl, setFileUrl] = useState<string>('')
    const [tableDetails, setTableDetails] = useState<any>()
    const [newRCH, setNewRCH] = useState<any>({ index: 0, id: 'none' })
    const [newPhone, setNewPhone] = useState<any>({ index: 0, id: 'none' })
    // const [refresh, setRefresh] = useState<boolean>(false);
    const [editableRCHIndex, setEditableRCHIndex] = useState<number>()
    const [editablePhoneIndex, setEditablePhoneIndex] = useState<number>()
    const [progress, setProgress] = useState<number>(0)
    const [toSearch, setToSearch] = useState<any>('RCH_ID')
    const [toSearchInput, setToSearchInput] = useState<string>()
    const [isLoading, setIsLoading] = useState(false)
    const [isAdmin, setIsAdmin] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("subRole") == undefined) {
            navigate("/")
        }
        else {
            if (localStorage.getItem("subRole") == "super") {
                let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
            }
            else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
                let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
            }
            else if (localStorage.getItem("subRole") == 'user') {
                let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
            }
            else {
                let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
            }
        }
    }, [])

    const checkPermissions = async () => {
        const Resp = await auth.currentUser?.getIdTokenResult(true)
        console.log('Claims ', Resp?.claims);
        if (!Resp)
            return;

        if (!Resp.claims) {
            return;
        }

        const claims = Resp.claims;
        if (!claims['admin'])
            return;
        if (claims['type'] !== 'super')
            return;

        setIsAdmin(true);
    }

    useEffect(() => {
        checkPermissions();
    }, [auth, auth.currentUser])


    const onScreenLoad = () => {
        firebase
            .app()
            .functions('asia-south1')
            .httpsCallable('patientDirectoryNew')({
                timestamp: null,
                id: null,
            })
            .then((response) => {
                console.log('responseNew', response)
                setTableDetails(response)
            })
            .catch((error) => alert(error))
    }

    React.useEffect(() => {
        onScreenLoad()
    }, [])

    const onSearch = () => {
        firebase
            .app()
            .functions('asia-south1')
            .httpsCallable('searchPatientDirectory')({
                type: toSearch == 'PHONE' ? 'Phone' : 'RCH_ID',
                value: toSearchInput,
            })
            .then(async (response) => {
                if (response.data.length < 1) {
                    alert(`No matching ${toSearch.toLowerCase()} found`);
                } else {
                    setTableDetails(response)
                }
            })
            .catch((error) => alert(error))
    }

    const uploadExcel = async (data: any) => {
        const todayDate = new Date();
        const currentMonthDate = new Date(todayDate);
        const formatDate = moment(currentMonthDate).format("DD_MM_YY_hh_mm_ss")
        console.log("fomat", formatDate)
        setIsLoading(true)
        const apiFetch = async () => {
            let fbToken = localStorage.getItem('fbToken')
            axios({
                method: 'post', //you can set what request you want to be
                url: 'https://uploadfile-qptmgxerba-el.a.run.app',
                data: {
                    filename: formatDate + '.xlsx',
                    token: fbToken
                },
                headers: {
                    Authorization: 'c557b664a6c811edafa10242ac120002',
                },
            })
                .then((res) => {
                    setIsLoading(false)
                })
                .catch(function (error) {
                    setIsLoading(false)
                    setProgress(1)
                    alert(error)
                })
        }
        const storage = firebase.storage()
        const storageRef = storage.ref(`/upload/${formatDate + '.xlsx'}`).put(data)
        storageRef.on(
            'state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
                )
                setProgress(progress)
            },
            (err) => {
                console.log('Error: ', err)
            },

            () => {
                storage
                    .ref('upload')
                    .child(formatDate + '.xlsx' as any)
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                        console.log('fire', fireBaseUrl)
                        setFileUrl(fireBaseUrl)
                        apiFetch()
                    })
            },
        )
    }

    const readExcel = (file: any) => {
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsArrayBuffer(file)
            fileReader.onload = (e) => {
                const bufferArray = e?.target?.result
                const wb = XLSX.read(bufferArray, { type: 'buffer' })
                const wsname = wb.SheetNames[0]
                const ws = wb.Sheets[wsname]
                const data = XLSX.utils.sheet_to_json(ws)
                resolve(data)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
        promise.then((data: any) => {
            if (data.length < 1) alert('Invalid or Blank sheet')
            else console.log('data', data)
        })
    }

    const viewMore = () => {
        firebase
            .app()
            .functions('asia-south1')
            .httpsCallable('patientDirectoryNew')({
                timestamp: tableDetails.data[tableDetails.data.length - 1].timestamp,
                id: tableDetails.data[tableDetails.data.length - 1].id,
            })
            .then((response) => {
                setTableDetails({ data: [...tableDetails.data, ...response.data] })
            })
            .catch((error) => alert(error))
    }

    const updatePhoneAndRCH = () => {
        console.log('newRCH[0].id', newRCH.id)
        firebase
            .app()
            .functions('asia-south1')
            .httpsCallable('updateUserPhoneAndRCHID')({
                RCH_ID:
                    newRCH.id != 'none'
                        ? newRCH.id
                        : tableDetails.data[newPhone.index].RCH_ID,
                Phone:
                    newPhone.id != 'none'
                        ? newPhone.id
                        : tableDetails.data[newPhone.index].Phone,
                id:
                    newRCH.id != 'none'
                        ? tableDetails.data[newRCH.index].id
                        : tableDetails.data[newPhone.index].id,
            })
            .then((response) => {
                setTableDetails(response)
                window.location.reload()
            })
            .catch((error) => alert(error))
    }

    const editRCH = (i: number, data: any) => {
        setNewRCH({ index: i, id: data })

    }

    const editPhone = (i: number, data: any) => {
        setNewPhone({ index: i, id: data })


    }

    const forButtonDisable = () => {

        if (newRCH.id == undefined && newPhone.id == undefined) return true
        else return false
    }

    return (
        <div className="container my-5 pe-0" style={{ overflowX: 'scroll' }}>
            <div className="row mb-4">
                <div className="col-md-6">
                    <div className="searchAndSelectContainer d-flex p-0 w-100 position-relative">
                        <select
                            className="patientSearch"
                            onChange={(e) => setToSearch(e.target.value)}
                        >
                            <option>RCH_ID</option>
                            <option>PHONE</option>
                        </select>
                        <input
                            type="text"
                            placeholder={
                                toSearch == 'RCH_ID' ? 'Enter RCH ID' : 'Enter Phone'
                            }
                            className=""
                            onChange={(e) => setToSearchInput(e.target.value)}
                            onKeyDown={(event) => (event.keyCode == 13 ? onSearch() : null)}

                        />
                        <div style={{ cursor: "pointer", width: "50px" }} onClick={() => onSearch()}>
                            <img src={SearchIcon} alt="" className="search_icon" />
                        </div>
                    </div>
                </div>

            </div>
            <div className="container">
                <div className="row">
                    <div className="card pb-3 patientDataCard mb-4">
                        <div className="row p-3 headingContainer d-flex align-items-center">
                            <div className="col-md-3 p-0 d-flex justify-content-start">
                                <h5 onClick={() => console.warn(tableDetails.data, newRCH)}>
                                    Patient Data
                                </h5>
                            </div>
                            <div className="col-md-9 d-flex justify-content-end"></div>
                        </div>

                        {tableDetails ? (
                            <div className="row">
                                <div className="table-responsive p-0 mb-3">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="col-md-5">
                                                    RCH ID
                                                </th>
                                                <th className="col">Phone No.</th>
                                                <th className="col">Name</th>
                                                <th className="col">GDM Status</th>
                                                <th className="col">District</th>
                                                <th className="col">LMP Date</th>
                                                <th className="col">Husband Name</th>
                                                <th className="col">Date Of Upload</th>
                                                <th className="col">EDD Date</th>
                                                <th className="col">Case No.</th>
                                                <th className="col">Registered As</th>
                                                {localStorage.getItem("subRole") != 'user' && isAdmin && <th className="col">Edit</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableDetails &&
                                                tableDetails.data.map((item: any, index: number) => {
                                                    const now = new Date(item.timestamp)
                                                    const date = now.toLocaleDateString()
                                                    const modifiedDate = date.replaceAll('/', '-')

                                                    return (
                                                        <tr
                                                            key={index}
                                                            style={{
                                                                border:
                                                                    index == editableRCHIndex
                                                                        ? '3px solid gray'
                                                                        : '',
                                                            }}
                                                        >
                                                            <td
                                                                scope="col"
                                                                className="col-md-5"

                                                            >
                                                                {setEditableRCHIndex.length > 0 &&
                                                                    index == editableRCHIndex ? (
                                                                    <input
                                                                        type="text"
                                                                        placeholder={`${item.RCH_ID}`}
                                                                        className="tableInput"
                                                                        onChange={(e) =>
                                                                            editRCH(index, e.target.value)
                                                                        }
                                                                    />
                                                                ) : (
                                                                    item.RCH_ID
                                                                )}
                                                            </td>
                                                            <td
                                                                scope="col"
                                                                className="col"
                                                                onClick={() =>
                                                                    index == editableRCHIndex
                                                                        ? setEditablePhoneIndex(index)
                                                                        : null
                                                                }
                                                            >
                                                                {setEditablePhoneIndex.length > 0 &&
                                                                    index == editablePhoneIndex ? (
                                                                    <input
                                                                        type="text"
                                                                        placeholder={`${item.Phone}`}
                                                                        className="tableInput"

                                                                        onChange={(e) =>
                                                                            editPhone(index, e.target.value)
                                                                        }
                                                                    />
                                                                ) : (
                                                                    item.Phone
                                                                )}
                                                            </td>
                                                            <td scope="col" className="col">
                                                                {item.Name}
                                                            </td>

                                                            <td scope="col" className="col">
                                                                {item.GDM}
                                                            </td>

                                                            <td scope="col" className="col">
                                                                {item.District}
                                                            </td>
                                                            <td scope="col" className="col">
                                                                {item.LMP_Date}
                                                            </td>
                                                            <td scope="col" className="col">
                                                                {item.Husband_Name}
                                                            </td>
                                                            <td scope="col" className="col">
                                                                {modifiedDate}
                                                            </td>
                                                            <td scope="col" className="col">
                                                                {item.EDD_Date}
                                                            </td>
                                                            <td scope="col" className="col">
                                                                {item.CaseNo}
                                                            </td>

                                                            <td scope="col" className="col">
                                                                {item.RegisteredAs}
                                                            </td>
                                                            {localStorage.getItem("subRole") != 'user' && isAdmin && <td
                                                                scope="col"
                                                                className="col"
                                                                onClick={() =>
                                                                    index != editableRCHIndex
                                                                        ? setEditableRCHIndex(index)
                                                                        : updatePhoneAndRCH()
                                                                }
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    textDecoration: 'underline',
                                                                    fontWeight: 'bold',
                                                                }}
                                                            >
                                                                {index != editableRCHIndex ? 'Edit' : 'Save'}
                                                            </td>}
                                                        </tr>
                                                    )
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <div className="F">
                                {' '}
                                <SpinnerCircular enabled={true} />
                            </div>
                        )}
                        <a className="viewMore" onClick={viewMore}>
                            View More
                        </a>
                    </div>
                    {localStorage.getItem("subRole") != 'user' && isAdmin && <div className="col-md-12">
                        <p className="para">
                            You have not uploaded any data yet, please select the Data to
                            upload
                        </p>
                    </div>}
                    {localStorage.getItem("subRole") != 'user' && isAdmin && <div className="col-md-12">

                        <div className="button position-relative uploadedFiles">
                            <span>Upload file</span>
                            <input
                                type="file"
                                className="inputClass fileUpload"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                onChange={(e) => {
                                    if (e.target.files !== null) {
                                        const file = e.target.files[0]

                                        uploadExcel(e.target.files[0])
                                    }
                                }}
                            />
                        </div>
                    </div>}

                    <div className="col-md-12">

                    </div>
                    <div className="col-md-12">
                        {progress > 1 && (
                            <p className="not">
                                {progress == 100
                                    ? `File has been Uploaded`
                                    : `Uploading file.. please wait(${progress}% complete)`}
                            </p>
                        )}
                        {progress > 1 ? (
                            <ProgressBar completed={progress} className="progressBar" />
                        ) : progress != 0 ? (
                            <div className="para" style={{ fontSize: '12px' }}>
                                <i>File not supported. Please try again</i>
                            </div>
                        ) : (
                            ''
                        )}
                        {isLoading && <SpinnerCircular enabled={true} className="mt-2" />}

                    </div>


                </div>
            </div>
        </div>
    )
}

export default PatientDirectory
