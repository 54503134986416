import React, { useEffect, useState } from 'react'
import plusIcon from '../assets/plus.png'
import Button from '../components/Button/Button'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import UploadedVideoCard from '../components/UploadedVideoCard'
import { postRequest, deleteRequest } from '../libs/ApiHelper'
import Cross from '../assets/crossIcon.png'
import Arrow from '../assets/arrowDown.png'
import firebase from '../connection/firebase'
import YouTube from 'react-youtube'
import { SpinnerCircular } from 'spinners-react'
import { useLocation, useNavigate } from 'react-router-dom'
import SidebarItems from '../components/SidebarItems'
import Thumbnail from '../assets/thumbnail.png'


function LearningResources() {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    }
    const [progress, setProgress] = useState(0)
    const [isOpen, setOpen] = useState(false)
    const [videoLink, setVideoLink] = useState<string>('')
    const [isExpendedView, setIsExpendedView] = useState(false)
    const [title, setTitle] = useState('')
    const [imageUrl, setImageUrl] = useState<any>([{ index: '', val: '' }])
    const [newImage, setNewImage] = useState<any>()
    const [newfile, setNewFile] = useState<any>()
    const [type, setType] = useState('ANM')
    const [learningResources, setLearningResources] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [selectedTitle, setSelectedTitle] = useState<any>()
    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    const [videoId, setVideoId] = useState<any>()
    console.log('select', selectedTitle)
    const opts = {
        height: '800',
        width: '1200',
        playerVars: {
            autoplay: 1,
        },
    }
    const [inputFields, setInputFields] = useState<any>([
        { videolink: '', videotitle: '', thumbnail: '', videodescription: '', chooseFile: '' },
    ])

    console.log('')

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem("subRole") == undefined) {
            navigate("/")
        }
        else {
            if (localStorage.getItem("subRole") == "super") {
                let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
            }
            else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
                let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
            }
            else if (localStorage.getItem("subRole") == 'user') {
                let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
            }
            else {
                let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
                if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
            }
        }
    }, [])

    const handleProgress = (e: any) => {
        console.log('responseDuration')
        if (isNaN(e.target.duration))
            return
        setProgress((e.target.currentTime / e.target.duration) * 100)
    }
    const _onReady = (event: any) => {
        event.target.pauseVideo()
    }
    const _onEnd = (event: any) => {
        let body = {
            VideoId: videoId,
            UserId: localStorage.getItem('phone'),
        }
        postRequest('AdminUsers/setResourcesVideos', body).then((res) => {
            console.log('resvideo', res)
        })
    }

    const handleFormChange = (
        index: number,
        event:
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        let data = [...inputFields]
        //@ts-ignore
        data[index][event.target.name] = event.target.value
        setInputFields([...data])
    }
    const handleFormChangeFiles = (index: number, val: any) => {
        console.log('index', index, val)
        let data = [...inputFields]
        //@ts-ignore
        data[index][val.target.name] = val.target.files
        setInputFields([...data])
    }
    const getLearningResource = () => {
        console.log(localStorage.getItem('subRole'))
        let body = {
            userId: localStorage.getItem('phone'),
            type: localStorage.getItem('subRole') == 'super' || localStorage.getItem("subRole") == 'user' ? type : localStorage.getItem('subRole') === "anm" ? "ANM" : localStorage.getItem('subRole') === "asha" ? "ASHA" : "Medical officers",
        }

        postRequest('AdminUsers/getLearningResources', body)
            .then((res) => {

                if (res?.result) {
                    const resources = res?.result.map((item: any) => {
                        let completedCount = 0;
                        item.options.map((data: any) => {
                            if (data.isCompleted === 'true') {
                                completedCount = completedCount + 1
                                item.completedCount = completedCount
                            }
                        })
                        return item
                    })
                    console.log('res', resources)
                    setLearningResources(resources)
                    setSelectedTitle(resources[selectedIndex])
                    setSelectedIndex(selectedIndex)
                    setIsLoading(false)
                } else setIsLoading(false)
            })
            .catch((error) => {
                console.log('error', error)
            })
    }
    useEffect(() => {
        setIsLoading(true)
        getLearningResource()
    }, [type])

    const upload = async (imageDetails: any, index: any) => {
        if (imageDetails == null) return
        const storage = firebase.storage()
        const storageRef = storage
            .ref(`/images/${imageDetails[0].name}`)
            .put(imageDetails[0])
        storageRef.on(
            'state_changed',
            (snapShot) => {
                //takes a snap shot of the process as it is happening
                console.log(snapShot)
            },
            (err) => {
                console.log('Error: ', err)
            },
            () => {
                storage
                    .ref('images')
                    .child(imageDetails[0].name)
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                        console.log(fireBaseUrl)
                        let data = [...inputFields]
                        //@ts-ignore
                        data[index]["thumbnail"] = fireBaseUrl
                        setInputFields([...data])

                    })
            },
        )
    }
    const onNewImageUpload = (index: any, val: any) => {
        if (val.target.files[0].size > 5 * 1024 * 1024) {
            alert("Please upload file less than 2mb");
        } else {
            const img = new Image();
            img.src = URL.createObjectURL(val.target.files[0]);
            img.onload = () => {
                if (Number(img.height / img.width) > 0.5 && (Number(img.height / img.width) < 0.8)) {
                    console.log('val', val)
                    setNewFile(URL.createObjectURL(val.target.files[0]))
                    handleFormChangeFiles(index, val)
                    setNewImage(val.target.files)
                    upload(val.target.files, index)
                }
                else {
                    alert("Please upload file with correct aspect ratio");
                }
            }
        }

    }
    const onSubmit = () => {
        let body = {
            title: title,
            type: type,
            options: inputFields,
        }
        postRequest('AdminUsers/createLearningResources', body)
            .then((res) => {
                console.log('res', res)
                alert('Uploaded Successfully')
                setIsExpendedView(false)
                setInputFields([
                    {
                        videolink: '',
                        videotitle: '',
                        thumbnail: '',
                        videodescription: '',
                        chooseFile: ''
                    },
                ])
                getLearningResource()
            })
            .catch((error) => {
                console.log('error', error)
            })
        console.log('inputFields', inputFields)
    }
    const onDelete = (item: any) => {
        let body = {
            videoId: item,
        }
        deleteRequest('AdminUsers/DeleteLearningResources', body).then((res) => {
            console.log('resdeleted', res)
            getLearningResource()
        })
    }
    const deleteActivity = (item: any) => {
        if (window.confirm('Do You Want To Delete')) {
            onDelete(item)
        }
    }


    const onVideo = (item: any, index: number) => {
        if (localStorage.getItem('subRole') == 'super') {
            setVideoId(item.id)
            setVideoLink(item.videolink)
            setOpen(true)
        }
        else {
            if (selectedTitle?.completedCount === undefined) {
                if (selectedIndex === 0 && index === 0) {
                    setVideoId(item.id)
                    setVideoLink(item.videolink)
                    setOpen(true)
                }
                if (selectedIndex > 0 && index === 0 && (learningResources[selectedIndex - 1] as any)?.completedCount === (learningResources[selectedIndex - 1] as any).options.length) {

                    setVideoId(item.id)
                    setVideoLink(item.videolink)
                    setOpen(true)
                    // }
                }
            }
            else {
                if (index <= selectedTitle?.completedCount) {
                    setVideoId(item.id)
                    setVideoLink(item.videolink)
                    setOpen(true)
                }
            }
        }
    }
    const getDisable = (index: number) => {
        if (localStorage.getItem('subRole') == 'super') return false;
        else {
            if (selectedTitle?.completedCount === undefined) {
                if (selectedIndex === 0 && index === 0) {
                    return false;
                }
                if (selectedIndex > 0 && index === 0 && (learningResources[selectedIndex - 1] as any)?.completedCount === (learningResources[selectedIndex - 1] as any).options.length) {
                    return false;
                }
                return true
            }
            else {
                if (index <= selectedTitle?.completedCount) {
                    return false;
                }
                return true
            }
        }
    }
    const youtubeURL = (url: string) => {
        console.log('url', url)
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
        var match = url.match(regExp)
        console.log('url', match && match[7].length == 11 ? match[7] : false)
        return match && match[7].length == 11 ? match[7] : url
    }
    return (
        <div className="container my-5 pe-0">
            <div className="row mb-4 w-100">
                {(localStorage.getItem('subRole') == 'super' || localStorage.getItem("subRole") == 'user') &&
                    <div className="col-md-5">
                        <div className="searchAndSelectContainer searchMain d-flex p-0 w-100 position-relative">
                            <input
                                type="text"
                                placeholder={'Upload content for'}
                                className=""
                                disabled

                            />
                            <div className='d-flex' style={{ background: "#8d2838", width: "100%", borderRadius: "8px 0px 0px 8px", padding: "0px 5px" }}>
                                <select
                                    className="patientSearch resourcesSelect"

                                    value={type}
                                    onChange={(event) => {
                                        setType(event.target.value)
                                    }}
                                >
                                    <option value={'ANM'}>ANM</option>
                                    <option value={'ASHA'}>ASHA</option>
                                    <option value={'Medical officers'}>Medical officers</option>
                                </select>
                                <div className='selectArrowContainer'>
                                    <img src={Arrow} alt="Arrow" className='selectArrow' />
                                </div>
                            </div>
                        </div>
                    </div>}
            </div>
            {localStorage.getItem('subRole') == 'super' && (
                <div
                    className={`d-flex justify-content-between align-items-center mb-4 ${isExpendedView ? 'createModuleText' : 'createModule px-3'
                        }`}
                >
                    <p>Create New Module</p>
                    <div className="plusIconContainer d-flex align-items-center justify-content-center">
                        {!isExpendedView && (
                            <img
                                src={plusIcon}
                                alt="Plus Icon"
                                onClick={() => setIsExpendedView(!isExpendedView)}
                            />
                        )}
                    </div>
                </div>
            )}
            {isExpendedView && (
                <form>
                    <div className="row w-100 mb-4">
                        <div className="col-md-6">
                            <input
                                type="text"
                                placeholder={'Add title'}
                                value={title}
                                className="mb-3 px-3 py-1 addTitleInput resourcesInput"
                                onChange={(event) => setTitle(event.target.value)}
                            />
                        </div>
                        <div className="col-md-6" />
                        {inputFields.map((item: any, index: any) => {
                            return (
                                <div className="row w-100 mb-4">
                                    <div className="col-md-12 text-left d-flex py-4">
                                        <p>Video {index + 1}</p>
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="videolink"
                                            placeholder={'Add video link'}
                                            value={item.videolink}
                                            className="mb-3 px-3 py-1 addTitleInput resourcesInput"
                                            onChange={(event) => handleFormChange(index, event)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <input
                                            type="text"
                                            name="videotitle"
                                            placeholder={'Add video title'}
                                            className="mb-3 px-3 py-1 addTitleInput resourcesInput"
                                            value={item.videotitle}
                                            onChange={(event) => handleFormChange(index, event)}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <div className='row d-flex mb-3'>
                                            <div className='col-md-6'>
                                                <img
                                                    src={item.thumbnail ? item.thumbnail : Thumbnail}
                                                    alt=""
                                                    className="thumbnailImage me-4 learningThumbnail"
                                                />
                                            </div>
                                            <div className='col-md-6 d-flex align-items-center'>
                                                <div className="button position-relative chooseFiles mb-0">
                                                    <span style={{ color: '#fff' }}>Choose file</span>
                                                    <input
                                                        type="file"
                                                        name="chooseFile"
                                                        accept="image/jpeg, image/png, image/jpg"
                                                        id="inputImage"

                                                        className="uploadButton fileChoose"
                                                        onChange={(e) => onNewImageUpload(index, e)}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-md-6">
                                        <textarea
                                            placeholder={'Add video description'}
                                            name="videodescription"
                                            className="mb-3 px-3 py-3 addTitleInput resourcesInput"
                                            value={item.videodescription}
                                            onChange={(event) => handleFormChange(index, event)}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                        <div className="col-md-6 d-flex flex-column align-items-start">
                            <div
                                className="createModule w-100 d-flex px-3 justify-content-between align-items-center mb-2"
                                onClick={() => {
                                    let addFields = inputFields
                                    addFields.push({
                                        videolink: '',
                                        videotitle: '',
                                        thumbnail: '',
                                        videodescription: '',
                                        chooseFile: ''
                                    })
                                    setInputFields([...addFields])
                                }}
                            >
                                <p>Add another video in this module</p>
                                <div className="plusIconContainer d-flex align-items-center justify-content-center">
                                    <img src={plusIcon} alt="Plus Icon" />
                                </div>
                            </div>
                            <Button
                                buttonText={'Submit'}
                                onButtonClick={onSubmit}
                                className="submitButton"
                            />
                        </div>
                    </div>
                </form>
            )}
            <div className="row w-100">
                {learningResources.length > 0 && <p className="uploadedModule mb-4">Uploaded Module</p>}
                <div className="row mb-4">
                    {learningResources.length > 0 ? (
                        <Carousel responsive={responsive}>
                            {learningResources?.map((item: any, index: any) => {
                                return (
                                    <div
                                        className={`card sliderItem d-flex align-items-center justify-content-center ${selectedIndex === index ? "activeCard" : ""}`}
                                        onClick={() => {
                                            console.log('indexvalue', item.title, index)
                                            setSelectedTitle(item)
                                            setSelectedIndex(index)
                                        }}
                                    >
                                        <p> {item?.title}</p>
                                    </div>
                                )
                            })}
                        </Carousel>
                    ) : (
                        <h4 className='pt-5'>No content found</h4>
                    )}
                </div>
            </div>
            {!isLoading ? <div className="row w-100">
                {selectedTitle?.options.length > 0 && <p className="uploadedModule mb-4">Uploaded Videos</p>}
                <div className="row">
                    {selectedTitle &&
                        selectedTitle?.options.map((item: any, index: any) => {
                            return (
                                <UploadedVideoCard
                                    title={item.videotitle}
                                    subtitle={item.videodescription}
                                    item={item}
                                    Onclick={() => deleteActivity(item.id)}
                                    onVideo={(item) => onVideo(item, index)}
                                    isDisabled={false}//{getDisable(index)}
                                />
                            )
                        })}
                    {isOpen && (
                        <div className="container mb-3 pe-0">
                            <div className="row">

                                <div className="modal-video modalOverlay">
                                    <div className="modalInner" style={{ width: '60%' }}>
                                        <img
                                            src={Cross}
                                            alt="Cross Icon"
                                            onClick={() => setOpen(false)}
                                            width="20px"
                                            height="20px"
                                            className="modalInnerImage"
                                        />

                                        <YouTube
                                            videoId={youtubeURL(videoLink)}
                                            opts={opts}
                                            onReady={_onReady}
                                            onEnd={_onEnd}
                                        />

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div> : <SpinnerCircular />}
        </div>
    )
}

export default LearningResources
