import React from "react";
import ArrowDownDark from "../../assets/arrowDownDark.png";
import ArrowDown from "../../assets/arrowDown.png";
import GrayArrowDown from "../../assets/grayIcon.png";

type Props = {
    onMouseMove: () => void;
    onDropDown: () => void;
    onDropdownList: (title: string, index: number) => void;
    dropdownValue: string;
    isDropdownList: any;
    dropdownItems: any;
    dropDownIndex: number;
    className?: string;
    dropdownListClass?: string;
    isWhiteArrow?: boolean;
    isSingleDropdown?: boolean;
    isDisabled?: boolean;
};
function Dropdown({
    onMouseMove,
    onDropDown,
    dropdownValue,
    isDropdownList,
    dropdownItems,
    dropDownIndex,
    onDropdownList,
    className,
    dropdownListClass,
    isWhiteArrow,
    isSingleDropdown,
    isDisabled
}: Props) {
    const getSrc = () => {
        if (isWhiteArrow) return ArrowDown
        else if (isDisabled) return GrayArrowDown
        else return ArrowDownDark
    }
    const dropdownItemList = () => {
        return (
            <div className={`dropdownList ${dropdownListClass}`}>
                {dropdownItems && dropdownItems.map((item: any, index: number) => {
                    return (
                        <p
                            className={`d-flex align-items-center ${dropdownValue === item.name ? "item" : ""
                                }`}
                            onClick={() => onDropdownList(item.text == undefined ? item.name : item.text.en, item.category_id)}
                            key={index}
                        >
                            {item.text == undefined ? item.name : item.text.en}
                        </p>
                    );
                })}
            </div>
        );
    };
    return (
        <div className="w-100 position-relative" onMouseLeave={onMouseMove}>
            <div
                className={`dropdownContainer ${className} ${isDropdownList === dropDownIndex ? "dropdown_container" : ""
                    } ${isDisabled && "disabled"}`}
                onClick={onDropDown}
            >
                <p>{dropdownValue}</p>
                <img
                    src={getSrc()}
                    alt="Arrow Down"
                    className={`dropdownArrow ${isWhiteArrow && "dropdownWhiteArrow"}`}
                    style={{
                        transform: isDropdownList || isDropdownList === dropDownIndex
                            ? "translateY(-50%) rotate(180deg)"
                            : "",
                    }}
                />
            </div>
            {isDropdownList === dropDownIndex && dropdownItemList()}
            {isSingleDropdown && isDropdownList && dropdownItemList()}
        </div>
    );
}

export default Dropdown;
