import {
  BASE_URL,
  CONTENT_TYPE,
  GET_REQUEST,
  PATCH_REQUEST,
  POST_REQUEST,
  PUBLIC_TOKEN,
  DELETE_REQUEST
 } from '../constants/Constants';
 const HEADERS = {
  Accept: CONTENT_TYPE,
  'Content-Type': CONTENT_TYPE,
  'x-access-token': PUBLIC_TOKEN,
 };

 export const postRequest = async (Url: string, BODY: any) => {
  try {
   const response = await fetch('https://maaswasthyasangini.com/admin/' + Url, {
    method: POST_REQUEST,
    headers: {
      'Content-Type': CONTENT_TYPE
     },
    body: JSON.stringify(BODY),
   });
   const json = await response.json();
   if (json.error) {
    alert('some error occured');
   } else {
    return json;
   }
   console.log(json);
  } catch (error) {
   console.log("ERROR: ",error);
   return error;
  }
 };


 export const getRequest = async (Url: string) => {
  try {
   const response = await fetch(BASE_URL + Url, {
    method: GET_REQUEST,
    headers: HEADERS,
   });
   const json = await response.json();
   if (json.error) {
    alert('some error occured');
   } else {
    return json;
   }
   console.log(json);
  } catch (error) {
   console.log(error);
   return error;
  }
 };
 

 export const deleteRequest = async (Url: string, BODY: any) => {
  try {
   const response = await fetch('https://maaswasthyasangini.com/admin/' + Url, {
    method:   DELETE_REQUEST,
    headers: {
     'Content-Type': CONTENT_TYPE,
      },
    body: JSON.stringify(BODY),
   });
   const json = await response.json();
   if (json.error) {
    alert('some error occured');
   } else {
    return json;
   }
   console.log(json);
  } catch (error) {
   console.log(error);
   return error;
  }
 };
 export const patchRequest = async (Url: string, BODY: any, access: any) => {
  try {
   const response = await fetch(BASE_URL + Url, {
    method: PATCH_REQUEST,
    headers:{
      Accept: CONTENT_TYPE,
      'Content-Type': CONTENT_TYPE,
      'x-access-token': access,
     },
    body: JSON.stringify(BODY),
   });
   const json = await response.json();
   if (json.error) {
    alert('some error occured');
   } else {
    return json;
   }
   console.log(json);
  } catch (error) {
   console.log("API ERROR: ",error);
   return error;
  }
 };
