
export const BASE_URL = "https://localhost:3000";
export const additional_URL = "/api/user/admin/get";
export const PUBLIC_TOKEN =
  "";
export const CONTENT_TYPE = "application/json";
export const POST_REQUEST = "POST";
export const GET_REQUEST = "GET";
export const PATCH_REQUEST = "PATCH";
export const DELETE_REQUEST ='delete'
export const HEADERS = {
  "Content-Type": CONTENT_TYPE,
};
export const START_INDEX = 1
export const END_INDEX = 10