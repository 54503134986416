// import { useEffect,useState } from "react";
import React, { useState, useEffect, useRef } from "react";
import Checkmark from "../assets/checkmark.png";
import ArrowDown from "../assets/arrowDown.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import firebase from "../connection/firebase";
import { DASHBOARD_USER_TYPES } from "../libs/UtilityHelper";
import { SpinnerCircular } from "spinners-react";
import SidebarItems from "../components/SidebarItems";

import Icon from "../assets/backButton.png"


function SelectRole() {
  let navigate = useNavigate();
  const [isSelectRole, setIsSelectRole] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const [isSubCategory, setIsSubCategory] = useState(false);
  const [isOtp, setIsOtp] = useState(false);
  const [otpList, setOtpList] = useState<any>(["", "", "", ""]);
  const [phone, setPhone] = useState<string>("");
  const [role, setRole] = useState<string>("Select Role");
  const [subRole, setSubRole] = useState<string>();
  const [loader, setLoader] = useState<boolean>();

  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("subRole") == undefined) {
      // navigate("/")
    }
    else {
      if (localStorage.getItem("subRole") == "super") {
        let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
        let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == 'user') {
        let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
      }
      else {
        let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
      }
    }
  }, [])

  const onNextButton = () => {
    setIsSelectRole(false);
    setIsSubCategory(true);
  };
  const onCategoryNextButton = () => {
    setIsSubCategory(false);
    setIsLogin(true);
  };

  const num1 = React.createRef<HTMLInputElement>();

  const num2 = React.createRef<HTMLInputElement>();

  const num3 = React.createRef<HTMLInputElement>();

  const num4 = React.createRef<HTMLInputElement>();

  const handleOtp1Change = (value: any) => {
    otpList[0] = value;
    num2.current?.focus();
  };
  const handleOtp2Change = (value: any) => {
    otpList[1] = value;
    num3.current?.focus();
  };

  const handleOtp3Change = (value: any) => {
    otpList[2] = value;
    num4.current?.focus();
  };

  const handleOtp4Change = (value: any) => {
    otpList[3] = value;
  
  };



  const checkNavigation = () => {
    setLoader(true)
    
      firebase.auth().signInWithEmailAndPassword(phone + '@jhpiegoadmin.com', String(otpList).replace(/,/g, "") + '_admin').then(async(res) =>{
        const fbToken = await  res?.user?.getIdToken()
        localStorage.setItem('fbToken', fbToken ?? '')
        let selectedRole = Object.entries(DASHBOARD_USER_TYPES).find((item) => item[0] == subRole ? item[1] : "")![1]
      firebase
            .app()
            .functions("asia-south1")
            .httpsCallable("checkAdminExists")({
              type: Object.entries(DASHBOARD_USER_TYPES).find((item) => item[0] == subRole ? item[1] : "")![1],
              phone: phone ?? "",
            })
            .then(async (response) => {
              localStorage.setItem('fullName', response.data.fullname)
              localStorage.setItem('phone', response.data.phone)
              localStorage.setItem('subRole', Object.entries(DASHBOARD_USER_TYPES).find((item) => item[0] == subRole ? item[1] : "")![1]);
              setTimeout(() => {
                if(selectedRole == "super" || selectedRole == "state") return navigate("/dashboard");
                else if(selectedRole == "block" || selectedRole == "district")  return navigate("/dashboard");
                else if(selectedRole == "medical_officer" || selectedRole == "anm" || selectedRole == "asha")  return navigate("/dashboard");
                else return navigate("/learningResources");
              }, 500);
             
            }).catch((error)=> {
              alert(error);
              setLoader(false)
            })

    })
    .catch((error: any) => {
      alert(error)
      setLoader(false)

    });

  };

  const isOtpFilled = () => {
    if (num1.current?.value.length == 0) return false;
    else if (num2.current?.value.length == 0) return false;
    else if (num3.current?.value.length == 0) return false;
    else if (num4.current?.value.length == 0) return false;
    else return true;
  };

  const onLoginNext = () => {
    setLoader(true)
    setIsLogin(false);
        setIsOtp(true);
        setLoader(false)
   
  };

  return (
    <div className="row w-100 mainContainer">
      <div className="col-lg-8 col-md-8 backgroundImageContainer"></div>
      <div className={`contentContainer ${isSubCategory ? 'pb-5 pt-3' : 'py-5'}  col-lg-4 col-md-4`}>
          {isSubCategory && <img src={Icon} alt="< Back" className="arrowBack" color="#fff" onClick={()=> {
            setIsSelectRole(true)
            setIsSubCategory(false)
          }}/>}

        <h2
          className="mb-5 text-left"
          onClick={() => console.warn(Object.entries(DASHBOARD_USER_TYPES).find((item) => item[0] == subRole ? item[1] : "")![1])}
        >
          Facilitated Patient Monitoring
        </h2>
        {isSelectRole && (
          <>
            <p className="mb-4 selectRole">Select your role</p>
            <div className="position-relative mb-4">
              <select
                className="ion"
                onChange={(e) => {
                  setRole(e.target.value);
                 setSubRole (e.target.value == "ADMIN" ? "SUPER ADMIN" : "ANMs")
                }}
                onKeyDown={(event)=> event.keyCode == 13 ? onNextButton() : null}
              >
                <option disabled selected>
                  Select Role
                </option>
                <option>ADMIN</option>
                <option>HEALTHCARE WORKER</option>
              </select>
             
            </div>
            
            <button
              className="p-2 nextButton"
              onClick={onNextButton}
              disabled={role == "Select Role"}
              style={{
                background: role == "Select Role" ? "rgba(0,0,0,0.3)" : "",
              }}
            >
              Next
            </button>
          </>
        )}
        {isSubCategory && (
          <>
            <p className="mb-4 selectRole">Select your role</p>
            <div className="position-relative mb-4">
              {role == "ADMIN" ? (
                <select
                  className="ion"
                  onChange={(e) => {
                    setSubRole(e.target.value);
                  }}
                  onKeyDown={(event)=> event.keyCode == 13 ? onCategoryNextButton() : null}
                >
                  <option>SUPER ADMIN</option>
                  <option>STATE LEVEL ADMIN</option>
                  <option>DISTRICT LEVEL ADMIN</option>
                  <option>BLOCK LEVEL ADMIN</option>
                  <option>USER (VIEW ONLY)</option>
                </select>
              ) : (
                <select
                  className="ion"
                  onChange={(e) => {
                    setSubRole(e.target.value);
                  }}
                  onKeyDown={(event)=> event.keyCode == 13 ? onCategoryNextButton() : null}
                >
                 
                  <option>ANMs</option>
                  <option>ASHAs</option>
                  <option>MEDICAL OFFICERS</option>
                </select>
              )}
             
            </div>
            <button
              className="p-2 nextButton"
              onClick={onCategoryNextButton}
              disabled={role == "Select Role"}
              style={{
                background: role == "Select Role" ? "rgba(0,0,0,0.3)" : "",
              }}
            >
              Next
            </button>
          </>
        )}

        {isLogin && (
          <>
            <p className="mb-3 selectRole">Enter your phone number</p>
            <div className="row w-100 phoneContainer">
              <div className="col-md-3 position-relative selectContainer">
                <img src={ArrowDown} alt="Arrow Down" className="arrowDown" />
                <select name="" id="" className="countryCode">
                  <option value="">+91</option>
                  <option value="">+1</option>
                </select>
              </div>
              <div className="col-md-9 phoneInputContainer">
                <input
                  type="number"
                  className="phoneInput"
                  onChange={(e) => setPhone(e.target.value)}
                  onKeyDown={(event)=> event.keyCode == 13 ? onLoginNext() : null}
                />
              </div>
            </div>
            {loader && <div className="loaderRole">
                <SpinnerCircular enabled={true} />
              </div>}
            <button
              className={`p-2 nextButton`}
              style={{ background: phone.length < 10 ? "rgba(0,0,0,0.3)" : "" }}
              disabled={phone.length < 10}
              type="submit"
              onClick={onLoginNext}
            >
              Next
            </button>
          </>
        )}
        {isOtp && (
          <>
            <p className="mb-3 selectRole">Enter Passcode</p>
            <div className="container">
              <div className="row w-100 d-flex">
                <input
                  type="text"
                  maxLength={1}
                  className="otpInput"
                  size={1}
                  max="1"
                  pattern="[0-9]{1}"
                  onChange={(e) => handleOtp1Change(e.target.value)}
                  ref={num1}
                />

                <input
                  type="text"
                  maxLength={1}
                  className="otpInput"
                  size={1}
                  max="1"
                  pattern="[0-9]{1}"
                  onChange={(e) => handleOtp2Change(e.target.value)}
                  ref={num2}
                />

                <input
                  type="text"
                  maxLength={1}
                  className="otpInput"
                  size={1}
                  max="1"
                  pattern="[0-9]{1}"
                  onChange={(e) => handleOtp3Change(e.target.value)}
                  ref={num3}
                />

                <input
                  type="text"
                  maxLength={1}
                  className="otpInput"
                  size={1}
                  max="1"
                  pattern="[0-9]{1}"
                  onChange={(e) => handleOtp4Change(e.target.value)}
                  ref={num4}
                  onKeyDown={(event)=> event.keyCode == 13 ? checkNavigation() : null}
                />
              </div>
            </div>
            {loader && <div className="loaderRole">
                <SpinnerCircular enabled={true} />
              </div>}

            <button
              className="p-2 nextButton"
              disabled={!isOtpFilled()}
              style={{
                background: isOtpFilled() ? "" : "rgba(0,0,0,0.3)",
              }}
              onClick={() => checkNavigation()}
            >
              Next
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default SelectRole;
