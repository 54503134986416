import { useEffect, useState } from "react";
import Button from "../components/Button/Button";
import EditPromModal from "../components/EditPromModal/EditPromModal";
import ArrowDownIcon from "../assets/arrowDownDark.png";
import React from "react";
import firebase from "firebase";
import PromsOptionModal from "../components/PromsOptionModal";
import { SpinnerCircular } from "spinners-react";
import { useLocation, useNavigate } from "react-router-dom";
import SidebarItems from "../components/SidebarItems";

function Prom() {
  const [isLoader, setIsLoader] = useState(false)
  const [isEditFirstPromModal, setIsEditFirstPromModal] =
    useState<boolean>(false);
  const [isEditSecondPromModal, setIsEditSecondPromModal] =
    useState<boolean>(false);
  const [isEditThirdPromModal, setIsEditThirdPromModal] =
    useState<boolean>(false);
  const [promList, setPromList] = useState<any>();
  const [isPromOptionsModal, setIsPromOptionsModal] = useState<boolean>(false);
  const [promOptions, setPromOptions] = useState<any>();
  const [frequency, setFrequency] = useState<string | undefined>("month");
  const [weekDay, setWeekDay] = useState();
  const [options, setOptions] = useState([
    {
      name: {
        en: "",
        hi: "",
        mr: "",
      },
      points: 0,
    },
    {
      name: {
        en: "",
        hi: "",
        mr: "",
      },
      points: 0,
    },
  ]);
  const [questionsList, setQuestionsList] = useState({
    en: "",
    hi: "",
    mr: "",
  });

  const [toggle, setToggle] = useState<any>();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("subRole") == undefined) {
      navigate("/")
    }
    else {
      if (localStorage.getItem("subRole") == "super") {
        let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
        let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == 'user') {
        let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
      }
      else {
        let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
      }
    }
  }, [])

  const onCrossModal = () => {
    setIsEditFirstPromModal(false);
    setIsEditSecondPromModal(false);
    setIsEditThirdPromModal(false);
  };

  const getProms = () => {
    setIsLoader(true)
    firebase
      .app()
      .functions("asia-south1")
      .httpsCallable("getAllProms")({})
      .then((response) => {
        let val = Object.entries(response.data).map(([text, value]) => {
          let questions: any = value;
          questions.promId = text;
          return questions;
        });
        console.log(response.data);
        console.log("VAL", val);
        setPromList(val);
        setIsLoader(false)
      })
      .catch((error) => {
        console.log(error)
        setIsLoader(false)
      })
  };

  React.useEffect(() => {
    getProms();
  }, []);

  const onToggle = (id: string, active: boolean) => {
    // if (toggle == undefined) setToggle(false);
    // else setToggle(!toggle);
    firebase
      .app()
      .functions("asia-south1")
      .httpsCallable("activeInactiveProm")({
        status: !active,
        promId: id,
      })
      .then(async (response) => {
        getProms();
        console.log("toggle", response);
      })
      .catch((error: any) => alert(error));
  };

  const onCreateProm = () => {
    firebase
      .app()
      .functions("asia-south1")
      .httpsCallable("createProm")(
        JSON.stringify({
          question: questionsList,
          options: options,
          active: true,
          frequency: frequency,
          weekday: weekDay ?? "",
        })
      )
      .then(async (response) => {
        console.log(response);
        alert("Prom added successfully");
        onCrossModal();
        getProms();
      });
  };

  const onNext = () => {
    setIsEditFirstPromModal(false);
    setIsEditSecondPromModal(false);
    setIsEditThirdPromModal(true);
  };

  const getPromDay = (item: any) => {
    if (item?.scheduledOn?.type === "halfmonth") {
      return `(${item?.scheduledOn?.value}th day)`
    }
    else if (item?.scheduledOn?.type === "daysafter") {
      return `(after ${item?.scheduledOn?.value} days)`
    }
    else if (item?.scheduledOn?.type === "week") {
      let day = ['Sunday','Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ]
      return `(${day?.[item?.scheduledOn?.value]})`
    }
    else if (item?.scheduledOn?.type === "monthly" || item?.scheduledOn?.type === 'month') {
      if(item?.scheduledOn?.value === '01') return `(${item?.scheduledOn?.value}st day)`
      if(item?.scheduledOn?.value === '02') return `(${item?.scheduledOn?.value}nd day)`
      if(item?.scheduledOn?.value === '03') return `(${item?.scheduledOn?.value}rd day)`
      else return `(${item?.scheduledOn?.value}th day)`
    }
  }

  return (
    <div className="container my-5">
     
      <div className="container">
        <div className="row">
          <div className="card pb-3 patientDataCard">
            <div className="row p-3 headingMain headingContainer d-flex align-items-center">
              <div className="col-md-3 p-0 d-flex justify-content-start">
                <h5 onClick={() => console.warn(weekDay)}>Add & Edit Prom</h5>
              </div>
              <div className="col-md-9 d-flex justify-content-end">
                <Button
                  buttonText={"Add new prom"}
                  isDarkBg
                  icon={"+"}
                  className="addNewProm"
                  onButtonClick={() => setIsEditFirstPromModal(true)}
                />
              </div>
            </div>
            {!isLoader ? (
              <div className="row">
                <div className="table-responsive p-0 mb-3">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" className="col-md-5">
                          Prom Questions
                        </th>
                        <th className="col">Frequency</th>
                        <th className="col">Answer</th>
                        <th className="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {promList &&
                        promList.map((item: any, index: number) => {
                          return (
                            <tr key={index}>
                              <td scope="col" className="col-md-5">
                                {item?.question?.question?.en ??
                                  item.question.en}
                              </td>
                              <td scope="col" className="col">
                                {item.frequency ?? ""}{getPromDay(item)}
                              </td>
                              <td scope="col" className="col">
                               
                                <u
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setPromOptions(item.options);
                                    setIsPromOptionsModal(true);
                                  }}
                                >
                                  View
                                </u>
                              </td>
                              <td scope="col" className="col buttonContainer">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={item?.active}
                                    onClick={() =>
                                      onToggle(item?.promId ?? "", item?.active)
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="loaderContainer">
                {" "}
                <SpinnerCircular enabled={true} />
              </div>
            )}
            {isPromOptionsModal && promOptions && (
              <PromsOptionModal
                promOptions={promOptions}
                onClose={() => setIsPromOptionsModal(false)}
              />
            )}
            {isEditFirstPromModal && (
              <EditPromModal
                onClose={() => onCrossModal()}
                modalPosition={1}
                onNext={() => setIsEditSecondPromModal(true)}
                setQuestion={(val) => (questionsList.en = val)}
                addNewOption={() => {
                  setOptions([
                    ...options,
                    {
                      name: {
                        en: "",
                        hi: "",
                        mr: "",
                      },
                      points: 0,
                    },
                  ]);
                }}
                optionLength={options.length}
                answer={(val, i) => {
                  options[i].name.en = val;
                }}
                points={() => { }}
                onFrequency={() => { }}
                weekDay={() => { }}
                onPrevious={() => { }}
              />
            )}
            {isEditSecondPromModal && (
              <EditPromModal
                onClose={() => onCrossModal()}
                modalPosition={2}
                onNext={onNext}
                setQuestion={(val) => (questionsList.hi = val)}
                addNewOption={() => { }}
                optionLength={options.length}
                answer={(val, i) => {
                  options[i].name.hi = val;
                }}
                points={() => { }}
                onFrequency={() => { }}
                weekDay={() => { }}
                onPrevious={() => setIsEditSecondPromModal(false)}
              />
            )}
            {isEditThirdPromModal && (
              <EditPromModal
                onClose={() => onCrossModal()}
                modalPosition={3}
                onNext={() => {
                  setIsEditThirdPromModal(false);
                  onCreateProm();
                }}
                setQuestion={(val) => (questionsList.mr = val)}
                addNewOption={() => { }}
                optionLength={options.length}
                answer={(val, i) => {
                  options[i].name.mr = val;
                }}
                points={(val, i) => (options[i].points = val)}
                onFrequency={(val) =>
                  setFrequency(
                    val == "Monthly"
                      ? "month"
                      : val == "Weekly"
                        ? "week"
                        : "halfmonth"
                  )
                }
                weekDay={(val) => setWeekDay(val)}
                onPrevious={() => setIsEditThirdPromModal(false)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Prom;
