import { useEffect, useState } from 'react'
import SearchIcon from '../assets/searchIconDark.png'
import Button from '../components/Button/Button'
import Dropdown from '../components/Dropdown/Dropdown'
import ArrowDownSvg from '../assets/arrowDown.svg'
import AddAdminModal from '../components/AddAdminModal/AddAdminModal'
import firebase from 'firebase'
import { SpinnerCircular } from 'spinners-react'
import { useLocation, useNavigate } from 'react-router-dom'
import SidebarItems from '../components/SidebarItems'
import { auth } from '../connection/firebase'
import moment from 'moment'

function RoleManagement() {
  const [isDistrictDropdown, setIsDistrictDropdown] = useState(false)
  const [districtDropdownValue, setDistrictDropdownValue] = useState(
    'District Data',
  )
  const [admins, setAdmins] = useState<any>([])
  const [selectedIndex, setSelectedIndex] = useState(-1)
  const onDistrictDropdown = (title: string) => {
    setDistrictDropdownValue(title)
    setIsDistrictDropdown(false)
  }
  const [isAddAdminModal, setIsAddAdminModal] = useState(false)
  const [isDropdownList, setIsDropdownList] = useState<any>()
  const [isEditAdminModal, setIsEditAdminModal] = useState<any>()
  const [isLoader, setIsLoader] = useState(false)
  const [stateValue, setStateValue] = useState<any>('')
  const [districtValue, setDistrictValue] = useState<any>('')
  const [blockValue, setBlockValue] = useState<any>('')
  const [fullName, setFullName] = useState('')
  const [phoneNumberInput, setPhoneNumberInput] = useState('')
  const [isTypeDropdown, setIsTypeDropdown] = useState(false)
  const [typeDropdownValue, setTypeDropdownValue] = useState(
    'Select Access Type',
  )
  const [searchInputText, setSearchInputText] = useState('')
  const [isAdmin, setIsAdmin] = useState(false);
const [progress, setProgress] = useState<number>(0)

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("subRole") == undefined) {
      navigate("/")
    }
    else {
      if (localStorage.getItem("subRole") == "super") {
        let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
        let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == 'user') {
        let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
      }
      else {
        let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
      }
    }
  }, [])

  const onTypeDropdown = (title: string) => {
    setTypeDropdownValue(title)
    setIsTypeDropdown(false)
  }

  const getType = () => {
    if (typeDropdownValue === 'State Level Access') return 'state'
    if (typeDropdownValue === 'District Level Access') return 'district'
    if (typeDropdownValue === 'Block Level Access') return 'block'
  }

  const clearCache = () => {
    setFullName('')
    setPhoneNumberInput('')
    setStateValue('Select State')
    setDistrictValue('Select District')
    setBlockValue('Select Block')
    setTypeDropdownValue('Select Access Type')
  }

  const getAdmins = () => {
    setIsLoader(true)
    firebase
      .app()
      .functions('asia-south1')
      .httpsCallable('getAdminsNew')({
        type: localStorage.getItem('subRole') ?? '',
        phone: localStorage.getItem('phone') ?? '',
        search: searchInputText ? searchInputText : null,
        page: 0,
      })
      .then((response) => {
        setAdmins(response.data)
        setIsLoader(false)
        // console.log('res', response.data)
      })
      .catch((error) => {
        setIsLoader(false)
        console.log(error)
      })
  }

  const onDropdown = (index: number) => {
    if (selectedIndex === index) {
      setIsDropdownList(!isDropdownList)
    }
    setSelectedIndex(index)
  }

  useEffect(() => {
    getAdmins()
  }, [])

    const checkPermissions = async () => {
        const Resp = await auth.currentUser?.getIdTokenResult(true)
        // console.log('Claims ', Resp?.claims);
        if (!Resp)
            return;

        if (!Resp.claims) {
            return;
        }

        const claims = Resp.claims;
        if (!claims['admin'])
            return;
        if (claims['type'] !== 'super')
            return;

        setIsAdmin(true);
    }

    useEffect(() => {
        checkPermissions();
    }, [auth, auth.currentUser])

  const onCreateAdmin = () => {
    setIsLoader(true)
    console.log('districtValue', districtValue)
    firebase
      .app()
      .functions('asia-south1')
      .httpsCallable('createUpdateAdmin')({
        type: getType(),
        phone: phoneNumberInput,
        admintype: localStorage.getItem('subRole'),
        adminphone: localStorage.getItem('phone'),
        fullname: fullName,
        oldtype: null,
        oldphone: null,
        state: stateValue ? stateValue : null,
        district:
          districtValue === 'Select District' || districtValue === ''
            ? null
            : districtValue,
        block:
          blockValue === 'Select Block' || blockValue === ''
            ? null
            : blockValue,
      })
      .then(async (response) => {
        console.log(response)
        alert('Admin Created Successfully')
        setTimeout(() => {
          getAdmins()
        }, 5000)
        setIsAddAdminModal(false)
        clearCache()
        setIsLoader(false)
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoader(false)
        setIsAddAdminModal(false)
      })
  }

  const onEditAdmin = () => {
    setIsLoader(true)
    getType()
    firebase
      .app()
      .functions('asia-south1')
      .httpsCallable('createUpdateAdmin')({
        type: getType() ? getType() : isEditAdminModal.type,
        phone: phoneNumberInput ? phoneNumberInput : isEditAdminModal.phone,
        admintype: localStorage.getItem('subRole'),
        adminphone: localStorage.getItem('phone'),
        fullname: fullName ? fullName : isEditAdminModal.fullname,
        oldtype: isEditAdminModal.type,
        oldphone: isEditAdminModal.phone,
        state: stateValue ? stateValue : isEditAdminModal.state,
      
        district:
          districtValue === 'Select District' || districtValue === ''
            ? null
            : districtValue,
        block:
          blockValue === 'Select Block' || blockValue === ''
            ? null
            : blockValue,
      })
      .then(async (response) => {
        console.log(response)
        alert('Edit Successfully')
        setTimeout(() => {
          getAdmins()
        }, 5000)

        setIsEditAdminModal(false)
        clearCache()
        setIsLoader(false)
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoader(false)
      })
  }

  const onAdminDelete = (item: any) => {
    setIsLoader(true)
    firebase
      .app()
      .functions('asia-south1')
      .httpsCallable('removeAdmin')({
        type: item.type,
        phone: item.phone,
        admintype: localStorage.getItem('subRole') ?? '',
        adminphone: localStorage.getItem('phone') ?? '',
      })
      .then((response) => {
        console.log('res', response)
        alert('Deleted Successfully')
        setTimeout(() => {
          getAdmins()
        }, 5000)
        setIsLoader(false)
      })
      .catch((error) => {
        console.log('error', error)
        setIsLoader(false)
      })
  }

  const onDelete = (item: any) => {
    if (window.confirm('Do You Want To Delete')) {
      onAdminDelete(item)
    }
  }

  const getPreviousValues = (item: any) => {
    setFullName(item.fullname)
    setPhoneNumberInput(item.phone)
    if (item) {
      setTypeDropdownValue(`${item.type} Level Access`)
      console.log('type', item.type)
      setStateValue(item.state)
      if (item.district) setDistrictValue(item.district)
      if (item.block) setBlockValue(item.block)
      console.log('state', stateValue)
      console.log('district', districtValue)
      console.log('block', blockValue)
    }
  }

  const onEdit = (item: any) => {
    setIsEditAdminModal(item)
    getPreviousValues(item)
  }

  const apiCall = async (filename: string, type: string) => {
      // createBulkUsers
      firebase.app().functions('asia-south1').httpsCallable('createBulkUsers')({
          'type': type, 
          'filename': filename
      }).then((response) => {
          console.log('createBulkUsers', response);
          alert('Data uploaded');
      }).catch((error) => alert(error));
  }


    const uploadExcel = async (data: any, type: string = 'anm') => {
        const todayDate = new Date();
        const currentMonthDate = new Date(todayDate);
        const formatDate = moment(currentMonthDate).format("DD_MM_YY_hh_mm_ss")
        console.log("fomat", formatDate)
        const storage = firebase.storage()
        const storageRef = storage.ref(`/${type}/${formatDate + '.xlsx'}`).put(data)
        storageRef.on(
            'state_changed',
            (snapShot) => {
                const progress = Math.round(
                    (snapShot.bytesTransferred / snapShot.totalBytes) * 100,
                )
                // setProgress(progress)
                console.log('Progress ::', progress);
            },
            (err) => {
                console.log('Error: ', err)
            },

            () => {
                storage
                    .ref('upload')
                    .child(formatDate + '.xlsx' as any)
                    .getDownloadURL()
                    .then((fireBaseUrl) => {
                        console.log('fire', fireBaseUrl)
                        apiCall(formatDate + '.xlsx', type);
                    })
            },
        )
    }


  return (
    <div>
      <div className="topBar mb-5">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-8 d-flex justify-content-start position-relative searchMainContainer">
                  <select className="nameSelectContainer">
                    <option>Name</option>
                    <option>Phone No.</option>
                  </select>
                  <input
                    type="text"
                    placeholder={'Enter Phone'}
                    className="search"
                    onChange={(e) => setSearchInputText(e.target.value)}
                    onKeyDown={(event) =>
                      event.keyCode == 13 ? getAdmins() : null
                    }
                  />
                    <div style={{ cursor: "pointer" }} onClick={() => getAdmins()}>
                        <img src={SearchIcon} alt="" className="search_icon" />
                    </div>
                </div>
                <div className="col-md-4">
                    <Button
                        buttonText={'Add new admin'}
                        className="addNewAdminButton w-100"
                        icon={'+'}
                        isLightBg={true}
                        onButtonClick={() => setIsAddAdminModal(true)}
                    />
                </div>
              </div>

            </div>
            <div className="col-md-4 d-flex justify-content-end align-items-center hide">
              <div className="col-md-8 position-relative">
                <Dropdown
                  className="frameDropdown withWhiteBorder"
                  dropdownListClass="dropdownListItems"
                  onMouseMove={() => {}}
                  onDropDown={() => {
                    setIsDistrictDropdown(!isDistrictDropdown)
                  }}
                  onDropdownList={(title: string, ind: number) =>
                    onDistrictDropdown(title)
                  }
                  dropdownValue={districtDropdownValue}
                  isDropdownList={isDistrictDropdown}
                  dropDownIndex={0}
                  dropdownItems={[
                    { name: 'Uttar Pradesh' },
                    { name: 'Haryana' },
                    { name: 'Himachal Pradesh' },
                  ]}
                  isWhiteArrow
                  isSingleDropdown
                />
              </div>
            </div>
          </div>
        </div>
        {/* { isAdmin && <div className='container' style={{marginLeft: '7.25rem'}}>
            <div className="d-flex mt-4" style={{ gap: "6rem" }}>
                <div className='d-flex' style={{gap: "2rem"}}>
                    <label htmlFor='UploadANMs' className='button addNewAdminButton w-100 px-4 d-flex' style={{ alignItems: 'center' }}>
                        <span style={{ color: '#8d283a', fontWeight: 'bold', textAlign:'center'}}>⏫ Upload ANMs</span>
                        <input
                            id='UploadANMs'
                            className="inputClass fileUpload d-none"
                            type='file'
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => {
                                if (e.target.files !== null) {
                                    const file = e.target.files[0]

                                    uploadExcel(file, 'anm')
                                }
                            }}
                        />
                    </label>
                    <label htmlFor='DownloadAMNs' className='button addNewAdminButton w-100 px-4 d-flex' style={{ alignItems: 'center' }}>
                        <span style={{ color: '#8d283a', fontWeight: 'bold', textAlign: 'center' }}>⏬ Download ANMs</span>
                        <input
                            id='DownloadAMNs'
                            className="inputClass fileUpload d-none"
                            type='file'
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => {
                                if (e.target.files !== null) {
                                    const file = e.target.files[0]

                                    // uploadExcel(e.target.files[0])
                                }
                            }}
                        />
                    </label>
                </div>

                <div className='d-flex' style={{ gap: "2rem" }}>
                    <label htmlFor='UploadASHAs' className='button addNewAdminButton w-100 px-4 d-flex' style={{ alignItems: 'center' }}>
                        <span style={{ color: '#8d283a', fontWeight: 'bold', textAlign: 'center' }}>⏫ Upload ASHAs</span>
                        <input
                            id='UploadASHAs'
                            className="inputClass fileUpload d-none"
                            type='file'
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => {
                                if (e.target.files !== null) {
                                    const file = e.target.files[0]

                                    uploadExcel(file, 'asha')
                                }
                            }}
                        />
                    </label>
                    <label htmlFor='DownloadASHAs' className='button addNewAdminButton w-100 px-4 d-flex' style={{ alignItems: 'center' }}>
                        <span style={{ color: '#8d283a', fontWeight: 'bold', textAlign: 'center' }}>⏬ Download ASHAs</span>
                        <input
                            id='DownloadASHAs'
                            className="inputClass fileUpload d-none"
                            type='file'
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            onChange={(e) => {
                                if (e.target.files !== null) {
                                    const file = e.target.files[0]

                                    // uploadExcel(e.target.files[0])
                                }
                            }}
                        />
                    </label>
                </div>
            </div>
        </div>} */}
      </div>
      <div className="container">
        {!isLoader ? (
          <div className="row aboutAdmin">
            {admins &&
              admins.map((item: any, index: number) => {
                return (
                  <div
                    className="d-flex adminContainer py-4 align-items-center"
                    style={{
                      background:
                        isDropdownList && selectedIndex === index
                          ? '#D0E8EB'
                          : '',
                    }}
                    key={index}
                  >
                    <div className="d-flex col-md-4">
                      <p className="admin">{item.fullname.substring(0, 1)}</p>
                      <div className="mx-4 userNameContainer">
                        <h4>{item.fullname}</h4>
                        <div className="d-flex">
                          {item.block && <p className="me-2">{item.block},</p>}
                          {item.district && (
                            <p className="me-2">{item.district},</p>
                          )}
                          {item.state && <p>{item.state}</p>}
                        </div>
                        <p>Phone No. {item.phone}</p>
                      </div>
                    </div>
                    <div className="col-md-2 d-flex justify-content-start h-100">
                      <p
                        className="adminType"
                        style={{
                          background:
                            isDropdownList && selectedIndex === index
                              ? '#ACDBE1'
                              : '#DDDDDD',
                        }}
                      >
                        {item.type} Level admin
                      </p>
                    </div>
                    <div className="col-md-2 d-flex justify-content-start h-100">
                        <p
                            className="adminType"
                            style={{
                                background:
                                    isDropdownList && selectedIndex === index
                                        ? '#ACDBE1'
                                        : '#DDDDDD',
                            }}
                        >
                            Code: {item.code}
                        </p>
                    </div>
                    <div className="col-md-4 justify-content-end align-items-center d-flex">
                      {isDropdownList && selectedIndex === index && (
                        <>
                          <Button
                            buttonText={'Edit'}
                            className="edit me-4"
                            isDarkBg
                            onButtonClick={() => onEdit(item)}
                          />
                          <i
                            className="fa fa-trash-o deleteIcon me-4"
                            aria-hidden="true"
                            onClick={() => onDelete(item)}
                          ></i>
                        </>
                      )}
                      {item.type !== 'super' && <img
                        src={ArrowDownSvg}
                        alt="Arrow Right"
                        style={{
                          transform:
                            isDropdownList && selectedIndex === index
                              ? ''
                              : 'rotate(-90deg)',
                        }}
                        onClick={() => onDropdown(index)}
                      />}
                    </div>
                  </div>
                )
              })}
          </div>
        ) : (
          <div className="loaderContainer">
            {' '}
            <SpinnerCircular enabled={true} />
          </div>
        )}
        {isAddAdminModal && (
          <AddAdminModal
            onClose={() => setIsAddAdminModal(false)}
            onFullName={(e: any) => setFullName(e.target.value)}
            onPhoneNumberInput={(e: any) => setPhoneNumberInput(e.target.value)}
            onStateDropdown={(e: any) => setStateValue(e.target.value)}
            onDistrictDropdownT={(e: any) => setDistrictValue(e.target.value)}
            onBlockDropdownT={(e: any) => setBlockValue(e.target.value)}
            onCreateAdmin={onCreateAdmin}
            stateValue={stateValue}
            districtValue={districtValue}
            onDropdown={() => setIsTypeDropdown(!isTypeDropdown)}
            onTypeDropdown={(title) => onTypeDropdown(title)}
            isTypeDropdown={isTypeDropdown}
            typeDropdownValue={typeDropdownValue}
            buttonText={'Add new admin'}
            text={'Add new'}
            disable={
              stateValue === '' ||
              typeDropdownValue === 'Select Access Type' ||
              phoneNumberInput.length < 10 ||
              fullName.length < 3
            }
            isLoader={isLoader}
          />
        )}
        {isEditAdminModal && (
          <AddAdminModal
            onClose={() => setIsEditAdminModal(false)}
            onFullName={(e: any) => setFullName(e.target.value)}
            onPhoneNumberInput={(e: any) => setPhoneNumberInput(e.target.value)}
            onStateDropdown={(e: any) => setStateValue(e.target.value)}
            onDistrictDropdownT={(e: any) => setDistrictValue(e.target.value)}
            onBlockDropdownT={(e: any) => setBlockValue(e.target.value)}
            onCreateAdmin={onEditAdmin}
            stateValue={stateValue}
            districtValue={districtValue}
            onDropdown={() => setIsTypeDropdown(!isTypeDropdown)}
            onTypeDropdown={(title) => onTypeDropdown(title)}
            isTypeDropdown={isTypeDropdown}
            typeDropdownValue={typeDropdownValue}
            phoneNumber={
              isEditAdminModal?.phone
                ? isEditAdminModal.phone
                : phoneNumberInput
            }
            buttonText={'Save'}
            selectedValue={stateValue}
            selectedDistrict={districtValue}
            // selecteBlock={isTypeDropdown}
            text={'Edit'}
            value={fullName}
            // blockvalue={}
            // districtvalue={districtValue}
            // statevalue={stateValue}
            phoneValue={phoneNumberInput}
            disable={
              stateValue === '' ||
              typeDropdownValue === 'Select Access Type' ||
              fullName.length < 3
            }
             stateDropdownValue={isEditAdminModal?.state ? isEditAdminModal?.state : "Select state"}
             districtDropdownValue={isEditAdminModal?.district ? isEditAdminModal?.district : "Select district"}
             blockDropdownValue={isEditAdminModal?.block ? isEditAdminModal?.block : "Select block"}
             isFromEdit
            fullName={
              isEditAdminModal?.fullname ? isEditAdminModal?.fullname : fullName
            }
            isLoader={isLoader}
            editableData={isEditAdminModal}
          />
        )}
      </div>
    </div>
  )
}

export default RoleManagement
