import DashboardIcon from "../assets/sidebarIcons/dashboardIcon.png";
import ContentIcon from "../assets/sidebarIcons/contentIcon.png";
import ManagementIcon from "../assets/sidebarIcons/managementIcon.png";
import ResourceIcon from "../assets/sidebarIcons/resourceIcon.png";
import NotificationIcon from "../assets/sidebarIcons/notificationIcon.png";
import CommunicationIcon from "../assets/sidebarIcons/communicationIcon.png";
import PromIcon from "../assets/sidebarIcons/promIcon.png";
import PatientDirectory from "../assets/sidebarIcons/patientDirectoryIcon.png";

const AdminItems = [
  {
    name: "Analytics",
    route: "/dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Content",
    route: "/iecContent",
    icon: ContentIcon,
  },
  {
    name: "Role Access Management",
    route: "/roleManagement",
    icon: ManagementIcon,
  },
  {
    name: "Learning Resources",
    route: "/learningResources",
    icon: ResourceIcon,
  },
  {
    name: "In App Notifications",
    route: "/notification",
    icon: NotificationIcon,
  },
  {
    name: "Patient Directory",
    route: "/patientDirectory",
    icon: PatientDirectory,
  },
  {
    name: "Communications",
    route: "/communication",
    icon: CommunicationIcon,
  },
  {
    name: "Prom",
    route: "/prom",
    icon: PromIcon,
  },
];

const WorkerItems = [
  {
    name: "Analytics",
    route: "/dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Learning Resources",
    route: "/learningResources",
    icon: ResourceIcon,
  },
  {
    name: "Patient Directory",
    route: "/patientDirectory",
    icon: PatientDirectory,
  }
];

const UserItems = [
  {
    name: "Analytics",
    route: "/dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Learning Resources",
    route: "/learningResources",
    icon: ResourceIcon,
  },
  {
    name: "Patient Directory",
    route: "/patientDirectory",
    icon: PatientDirectory,
  }
];

const StateLevelItems = [
  {
    name: "Analytics",
    route: "/dashboard",
    icon: DashboardIcon,
  },
  {
    name: "Role Access Management",
    route: "/roleManagement",
    icon: ManagementIcon,
  }
];
export default {WorkerItems, AdminItems, StateLevelItems, UserItems};
