import React, { useEffect } from 'react'
import Iframe from 'react-iframe'
import SidebarItems from './SidebarItems';
import { useLocation, useNavigate } from 'react-router-dom';


function Analytics() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("subRole") == undefined) {
      navigate("/")
    }
    else {
      if (localStorage.getItem("subRole") == "super") {
        let matchingRoute = SidebarItems.AdminItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.AdminItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == "state" || localStorage.getItem("subRole") == "district" || localStorage.getItem("subRole") == "block") {
        let matchingRoute = SidebarItems.StateLevelItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.StateLevelItems[0]?.route)
      }
      else if (localStorage.getItem("subRole") == 'user') {
        let matchingRoute = SidebarItems.UserItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.UserItems[0]?.route)
      }
      else {
        let matchingRoute = SidebarItems.WorkerItems.find((item) => item.route === location.pathname)
        if (matchingRoute == undefined) navigate(SidebarItems?.WorkerItems[0]?.route)
      }
    }
  }, [])

  return (
    <Iframe url="https://lookerstudio.google.com/embed/reporting/e78bdc71-74b6-47d9-9703-fe369ec52b5d/page/pG8DD"
      width="100%"
      height="320px"
      id=""
      className="analytics"
      display="block"
      position="relative" />
  )
}

export default Analytics